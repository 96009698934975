
import AbstractCRUDStore from './AbstractCRUDStore'
import trialRegistration from 'cruds/trialRegistration'
import sourceStore from './sourceStore'

class TrialRegistrationStore extends AbstractCRUDStore {
  constructor() {
    super()
    this.setCRUD(trialRegistration)
  }

  emptyObject() {
    return {
      client: {},
      source: {}
    }
  }

  transformRequestData(data) {
    sourceStore.setSiteUrl()
    const source = sourceStore.object
    data.source_attributes = source
    delete data.source

    const { client } = data
    data.client_attributes = client
    delete data.client
    delete data.password_filled_in
    console.log('transformRequestData - after:', data)
    return data
  }
}

export default new TrialRegistrationStore()
