import styled from 'styled-components'
import media from 'utils/mediaQueries'

const Wrapper = styled.div`
  padding: 4px 6px;
  margin: 0 20px;
  background-color: #333;
  justify-content: center;
  display: inline-flex;
  ${media.desktop`display: flex;`}
  @media print {
    display: none;
  }
`

export default Wrapper
