import React from "react";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import routes from "utils/routes";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import LocaleToggle from "containers/partials/LocaleToggle";

const Footer = (props) => {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Container maxWidth={false} sx={{ bgcolor: "#f4f4f4" }}>
      <Container maxWidth="xl">
        <Box sx={{ py: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <List
                sx={{ width: "100%", maxWidth: 360 }}
                component="nav"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    <FormattedMessage {...messages.figureOut} />
                  </ListSubheader>
                }
              >
                <ListItemButton component={Link} to={routes.features}>
                  <ListItemText
                    primary={<FormattedMessage {...messages.platform} />}
                  />
                </ListItemButton>
                <ListItemButton component={Link} to={routes.addons}>
                  <ListItemText
                    primary={<FormattedMessage {...messages.addons} />}
                  />
                </ListItemButton>
                <ListItemButton component={Link} to={routes.industries}>
                  <ListItemText
                    primary={<FormattedMessage {...messages.industries} />}
                  />
                </ListItemButton>
              </List>
            </Grid>
            <Grid item xs={3}>
              <List
                sx={{ width: "100%", maxWidth: 360 }}
                component="nav"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    <FormattedMessage {...messages.informationFor} />
                    ...
                  </ListSubheader>
                }
              >
                <ListItemButton>
                  <ListItemText
                    primary={<FormattedMessage {...messages.developers} />}
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText
                    primary={
                      <FormattedMessage {...messages.businessPartners} />
                    }
                  />
                </ListItemButton>
              </List>
            </Grid>
            <Grid item xs={3}>
              <List
                sx={{ width: "100%", maxWidth: 360 }}
                component="nav"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    <FormattedMessage {...messages.stayConnected} />
                  </ListSubheader>
                }
              >
                <ListItemButton component={Link} to={routes.consultancy}>
                  <ListItemText
                    primary={<FormattedMessage {...messages.consultancy} />}
                  />
                </ListItemButton>
                <ListItemButton component={Link} to={routes.support}>
                  <ListItemText
                    primary={<FormattedMessage {...messages.support} />}
                  />
                </ListItemButton>
                {/* <ListItemButton>
                  <ListItemText primary="Znajdź Partnera Handlowego" />
                </ListItemButton> */}
              </List>
            </Grid>
            <Grid item xs={3}>
              <List
                sx={{ width: "100%", maxWidth: 360 }}
                component="nav"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    <FormattedMessage {...messages.about} />
                  </ListSubheader>
                }
              >
                <ListItemButton component={Link} to={routes.news}>
                  <ListItemText
                    primary={<FormattedMessage {...messages.news} />}
                  />
                </ListItemButton>
                <ListItemButton component={Link} to={routes.democenter}>
                  <ListItemText
                    primary={<FormattedMessage {...messages.demoCenter} />}
                  />
                </ListItemButton>
                {/* <ListItemButton>
                  <ListItemText primary="Relacje inwestorskie" />
                </ListItemButton> */}
                <ListItemButton component={Link} to={routes.aboutus}>
                  <ListItemText
                    primary={<FormattedMessage {...messages.about} />}
                  />
                </ListItemButton>
                <ListItemButton component={Link} to={routes.contact}>
                  <ListItemText
                    primary={<FormattedMessage {...messages.contact} />}
                  />
                </ListItemButton>
              </List>
            </Grid>
          </Grid>
        </Box>
        <LocaleToggle />
        <hr />
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ display: "flex", flexGrow: 1 }}>
            <Button
              sx={{ my: 2, display: "block" }}
              color="inherit"
              size="small"
              component={Link}
              to={routes.contact}
            >
              <FormattedMessage {...messages.contact} />
            </Button>
            <Button
              sx={{ my: 2, display: "block" }}
              color="inherit"
              size="small"
              component={Link}
              to={routes.terms}
            >
              <FormattedMessage {...messages.terms} />
            </Button>
            <Button
              sx={{ my: 2, display: "block" }}
              color="inherit"
              size="small"
              component={Link}
              to={routes.privacy}
            >
              <FormattedMessage {...messages.privacy} />
            </Button>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Button
              sx={{ my: 2, display: "block" }}
              color="inherit"
              size="small"
              variant="text"
            >
              © 2016-2023 AndonCloud. Wszystkie prawa zastrzeżone
            </Button>
          </Box>
        </Box>
      </Container>
    </Container>
  );
};

export default Footer;
