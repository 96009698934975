import AbstractCrud from './AbstractCRUD'
import Environments from '../utils/environments'

class StaticPage extends AbstractCrud {
  constructor () {
    super()
    this.setApiUrl(Environments.API_URL)
    this.setResourcePath('static_pages')
    this.setAllowedMethods(['GET'])
  }
}

export default new StaticPage()
