/*
 * Section Messages
 *
 * This contains all the text for the Section component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  headerFirst: {
    id: 'app.containers.CalculatorSection.headerFirst',
    defaultMessage: 'How much do you '
  },
  headerSecond: {
    id: 'app.containers.CalculatorSection.headerSecond',
    defaultMessage: 'lose?'
  },
  description: {
    id: 'app.containers.CalculatorSection.description',
    defaultMessage: "Machine's downtime is pricy. The longer you have to wait for the help services, the more you pay, it's that easy. Calculate estimated prices of machine downtimes in your company and "
  },
  descriptionStrong: {
    id: 'app.containers.CalculatorSection.descriptionStrong',
    defaultMessage: 'find out how much you lose every year.'
  }
})
