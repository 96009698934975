/**
 *
 * Section
 *
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import messages from './messages'
import H2 from 'components/H2'
import H4 from 'components/H4'
import P from 'components/P'
import Card from './Card'
import MediaQuery from 'react-responsive'

const Wrapper = styled.section`
  width: 100vw;
  background: #fcfcfc;
  text-align: center;
  padding: 100px 10vw 100px 10vw;
  page-break-after: always;
  @media print {
    .seo {
      display: none;
    }
  }
`

const CardsWrapper = styled.div`
 display: table;
 padding-bottom: 100px;
 padding-top: 120px;
`
const MobileCardsWrapper = styled.div`
 display: flex;
 flex-direction: column;
 padding-bottom: 100px;
 padding-top: 120px;
`

export default class AboutUsSection extends React.Component { // eslint-disable-line react/prefer-stateless-function
  render () {
    return (
      <Wrapper>
        <H2>
          <FormattedMessage {...messages.headerFirst} />
          &nbsp;
          <strong>
            <FormattedMessage {...messages.headerSecond} />
          </strong>
        </H2>
        <P className='seo'>
          <strong><FormattedMessage {...messages.descriptionFirst} /></strong>
          &nbsp;
          <FormattedMessage {...messages.descriptionSecond} />
        </P>
        <MediaQuery query='(max-width: 1200px)'>
          <MobileCardsWrapper>
            <Card>
              <H4><FormattedMessage {...messages.cardOneTitle} /></H4>
              <P><FormattedMessage {...messages.cardOneSubitle} /></P>
            </Card>
            <Card>
              <H4><FormattedMessage {...messages.cardTwoTitle} /></H4>
              <P><FormattedMessage {...messages.cardTwoSubtitle} /></P>
            </Card>
            <Card>
              <H4><FormattedMessage {...messages.cardThreeTitle} /></H4>
              <P><FormattedMessage {...messages.cardThreeSubtitle} /></P>
            </Card>
          </MobileCardsWrapper>
        </MediaQuery>
        <MediaQuery query='(min-width: 1201px)'>
          <CardsWrapper>
            <Card>
              <H4><FormattedMessage {...messages.cardOneTitle} /></H4>
              <P><FormattedMessage {...messages.cardOneSubitle} /></P>
            </Card>
            <Card>
              <H4><FormattedMessage {...messages.cardTwoTitle} /></H4>
              <P><FormattedMessage {...messages.cardTwoSubtitle} /></P>
            </Card>
            <Card>
              <H4><FormattedMessage {...messages.cardThreeTitle} /></H4>
              <P><FormattedMessage {...messages.cardThreeSubtitle} /></P>
            </Card>
          </CardsWrapper>
        </MediaQuery>
      </Wrapper>
    )
  }
}
