/**
 *
 * ProfitCalculator
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import styled from 'styled-components'
import H3 from 'components/H3'
import P from 'components/P'
import ValueSlider from 'components/ValueSlider'
import SliderElement from './SliderElement'
import MediaQuery from 'react-responsive'
import messages from './messages'

const Wrapper = styled.div`
  margin-top: 60px;
  padding-bottom: 100px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export default class ProfitCalculator extends React.Component { // eslint-disable-line react/prefer-stateless-function
  state = {
    machinesCount: 15,
    machinesDowntimeFrequency: 2,
    helpNotificationDelay: 5,
    productionSuspensionCost: 2000
  }
  calculateDailyDelay () {
    const quantity = this.state.machinesCount
    const frequency = this.state.machinesDowntimeFrequency
    const delay = this.state.helpNotificationDelay
    return (quantity * frequency / 7 * delay / 60)
  }
  calculateDailyLoss () {
    const dailyDelay = this.calculateDailyDelay()
    const suspensionCost = this.state.productionSuspensionCost
    return dailyDelay * suspensionCost
  }
  render () {
    return (
      <Wrapper>
        <MediaQuery query='(max-width: 899px)'>
          <div>
            <Row>
              <SliderElement>
                <P><FormattedMessage {...messages.machinesCount} /></P><P><strong>{ this.state.machinesCount }</strong></P>
                <ValueSlider min={1} max={100} step={1} value={this.state.machinesCount} onChange={(value) => this.setState({ machinesCount: value })} />
              </SliderElement>
            </Row>
            <Row>
              <SliderElement>
                <P>
                  <FormattedMessage {...messages.machinesDowntimeFrequency} />
                </P>
                <P>
                  <strong>
                    { this.state.machinesDowntimeFrequency } <FormattedMessage {...messages.machinesDowntimeFrequencyWeek} />
                  </strong>
                </P>
                <ValueSlider min={1} max={30} step={1} value={this.state.machinesDowntimeFrequency} onChange={(value) => this.setState({ machinesDowntimeFrequency: value })} />
              </SliderElement>
            </Row>
            <Row>
              <SliderElement>
                <P><FormattedMessage {...messages.helpNotificationDelay} /></P><P><strong>{ this.state.helpNotificationDelay } min</strong></P>
                <ValueSlider min={2} max={20} step={1} value={this.state.helpNotificationDelay} onChange={(value) => this.setState({ helpNotificationDelay: value })} />
              </SliderElement>
            </Row>
            <Row>
              <SliderElement>
                <P>
                  <FormattedMessage {...messages.productionSuspensionCost} />
                </P>
                <P>
                  <strong>
                    <FormattedMessage {...messages.currency}>
                      {
                        (value) => <FormattedNumber value={this.state.productionSuspensionCost}  currency={value} />
                      }
                    </FormattedMessage>
                  </strong>
                </P>
                <ValueSlider min={300} max={10000} step={1} value={this.state.productionSuspensionCost} onChange={(value) => this.setState({ productionSuspensionCost: value })} />
              </SliderElement>
            </Row>
            <Row>
              <H3>
                <FormattedMessage {...messages.calcResultFirst} />
                <strong>
                  <FormattedMessage {...messages.currency}>
                    {
                      (value) => <FormattedNumber value={365 * this.calculateDailyLoss()}  currency={value} />
                    }
                  </FormattedMessage>
                </strong>
                &nbsp;
                <FormattedMessage {...messages.calcResultSecond} />
              </H3>
            </Row>
          </div>
        </MediaQuery>
        <MediaQuery query='(min-width: 900px)'>
          <div>
            <Row>
              <SliderElement>
                <P><FormattedMessage {...messages.machinesCount} /></P><P><strong>{ this.state.machinesCount }</strong></P>
                <ValueSlider min={1} max={50} step={1} value={this.state.machinesCount} onChange={(value) => this.setState({ machinesCount: value })} />
              </SliderElement>
              <SliderElement>
                <P><FormattedMessage {...messages.machinesDowntimeFrequency} /></P><P><strong>{ this.state.machinesDowntimeFrequency }<FormattedMessage {...messages.machinesDowntimeFrequencyWeek} /></strong></P>
                <ValueSlider min={1} max={30} step={1} value={this.state.machinesDowntimeFrequency} onChange={(value) => this.setState({ machinesDowntimeFrequency: value })} />
              </SliderElement>
              <SliderElement>
                <P><FormattedMessage {...messages.helpNotificationDelay} /></P><P><strong>{ this.state.helpNotificationDelay } min</strong></P>
                <ValueSlider min={2} max={20} step={1} value={this.state.helpNotificationDelay} onChange={(value) => this.setState({ helpNotificationDelay: value })} />
              </SliderElement>
            </Row>
            <Row>
              <SliderElement>
                <P>
                  <FormattedMessage {...messages.productionSuspensionCost} />
                </P>
                <P>
                  <strong>
                    <FormattedMessage {...messages.currency}>
                      {
                        (value) => <FormattedNumber value={this.state.productionSuspensionCost}  currency={value} />
                      }
                    </FormattedMessage> / h
                  </strong>
                </P>
                <ValueSlider min={300} max={10000} step={1} value={this.state.productionSuspensionCost} onChange={(value) => this.setState({ productionSuspensionCost: value })} />
              </SliderElement>
            </Row>
            <Row>
              <H3>
                <FormattedMessage {...messages.calcResultFirst} />
                <strong>
                  <FormattedMessage {...messages.currency}>
                    {
                      (value) => <FormattedNumber value={365 * this.calculateDailyLoss()}  currency={value} />
                    }
                  </FormattedMessage>
                </strong>
                &nbsp;
                <FormattedMessage {...messages.calcResultSecond} />
              </H3>
            </Row>
          </div>
        </MediaQuery>
      </Wrapper>
    )
  }
}

ProfitCalculator.contextTypes = {
  intl: PropTypes.object
}
