import AbstractCrud from './AbstractCRUD'
import Environments from '../utils/environments'
import axios from 'axios'

class Product extends AbstractCrud {
  constructor () {
    super()
    this.setApiUrl(Environments.PARTNERS_URL)
    this.setResourcePath('products')
    this.setAllowedMethods(['GET'])
  }

  async find (hostname) {
    const method = 'GET'
    this.guard(method)
    const url = `${this.resourceUrl}/find?hostname=${hostname}`
    const request = axios.get(url, {headers: {'Content-Type': 'application/json'}})
    return request
  }
}

export default new Product()
