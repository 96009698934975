/**
 *
 * Section
 *
 */

import React from 'react'
import styled from 'styled-components'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Row } from 'antd'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ReactComponent as Waste } from './assets/waste.svg'
import { ReactComponent as Paperless } from './assets/paperless.svg'
import { ReactComponent as Kpi } from './assets/kpi.svg'
import { ReactComponent as Bottleneck } from './assets/bottleneck.svg'
import H2 from 'components/H2'
import P from 'components/P'
import messages from './messages'

const Wrapper = styled(Container)`
  .benefits {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 5vw;
  }
  .benefit {
    padding: 5vw;
    width: 50%;
    &:first-child {
      border-bottom: solid 1px #efefef;
      border-right: solid 1px #efefef;
      margin-bottom: -1px;
      margin-right: -1px;
    }
    :last-child {
      border-top: solid 1px #efefef;
      border-left: solid 1px #efefef;
      margin-top: -1px;
      margin-left: -1px;
    }
    .icon {
      max-height: 160px;
      margin: 20px;
    }
    .title {
      font-size: 20pt;
      font-weight: bold;
      margin: 5px;
    }
    .description {
      font-size: 16pt;
      font-weight: 200;
    }
  }
`

class BenefitsSection extends React.Component { // eslint-disable-line react/prefer-stateless-function
  render () {
    return (
      <Wrapper maxWidth="xl">
        <Typography variant="h2" component="h1" gutterBottom>
          {this.props.intl.formatMessage(messages.title)}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <FormattedMessage {...messages.description} />
        </Typography>
        <div className='benefits'>
          <div className='benefit'>
            <Kpi className='icon' />
            <div className='title'><FormattedMessage {...messages.title0} /></div>
            <div className='description'><FormattedMessage {...messages.description0} /></div>
          </div>
          <div className='benefit'>
            <Paperless className='icon' />
            <div className='title'><FormattedMessage {...messages.title1} /></div>
            <div className='description'><FormattedMessage {...messages.description1} /></div>
          </div>
          <div className='benefit'>
            <Bottleneck className='icon' />
            <div className='title'><FormattedMessage {...messages.title2} /></div>
            <div className='description'><FormattedMessage {...messages.description2} /></div>
          </div>
          <div className='benefit'>
            <Waste className='icon' />
            <div className='title'><FormattedMessage {...messages.title3} /></div>
            <div className='description'><FormattedMessage {...messages.description3} /></div>
          </div>
        </div>
      </Wrapper>
    )
  }
}

export default injectIntl(BenefitsSection)
