import React, { Component } from 'react'
import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { observer } from 'mobx-react'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import styled from 'styled-components'
import calendarStore from 'stores/calendarStore'
import moment from 'moment' // /min/moment-with-locales'
import { injectIntl } from 'react-intl'
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const LOCK_TIME = 9 // minutes


const RenderHour = observer(({ hour, onChange}) => {

  const handleSelect = (datetime) => {
    calendarStore.setSelectedDateTime(datetime)
    console.log('handleSelect', {datetime}, calendarStore)
    onChange(datetime)
  }

  const datetime = moment(hour.datetime)
  let available = hour.available
  const hourFormatted = datetime.format('LT')
  const { selectedDateTime } = calendarStore
  const selected = selectedDateTime && selectedDateTime.isSame(datetime)
  const diff = moment().diff(datetime)
  console.log('diff:', diff, selectedDateTime, selected)

  if (diff > -LOCK_TIME * 60 * 1000) {
    available = false
  }

  if(selected){
    return (<Button onClick={(e) => handleSelect(datetime)} size="small" variant="contained" color="primary">{hourFormatted}</Button>)
  }
  else if(available){
    return (<Button onClick={(e) => handleSelect(datetime)} size="small" variant="outlined" color="primary">{hourFormatted}</Button>)
      
  } else {
    return (<Button size="small" variant="outlined" disabled>{hourFormatted}</Button>)
  }
})

const RenderDay = ({day, selected, onChange}) => {
  const { hours, date } = day

  const filteredHours = hours.filter((d) => {
    const { datetime } = d
    const _datetime = new Date(datetime)
    return _datetime >  new Date()
  })
  
  return (
    filteredHours.map((hour) => {
        return <Box p={0.5}><RenderHour hour={hour} key={hour.datetime} onChange={onChange}/></Box>
      })
  )
}

export const Days = ({dates, onChange}) => {

  const filteredDates = dates.filter((d) => {
    const { date } = d
    const _date = new Date(date)
    const day = _date.getDay();
    return day > 0 && day < 6
  })

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return <div>
    <Tabs
      m={-2}
      value={value}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      >
        {dates.map((date, i) =>
          <Tab label={date.date} {...a11yProps(i)}/>
        )}
      </Tabs>
        {dates.map((date, i) =>
          <TabPanel value={value} index={i}>
            <Grid container>
              <RenderDay day={date} key={date.date} onChange={onChange} />
            </Grid>
          </TabPanel>
        )}
      
      </div>
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const App = ({userId, onChange}) => {

  const [dates, setDates] = useState([]);
  
  useEffect(() => {
    moment.locale('pl')

    const fetchDates = async () => {
      await calendarStore.find(userId)
      let { object } = calendarStore
      const { dates } = object

      setDates(dates)
    }

    fetchDates();
  }, []);

  return <Days dates={dates} onChange={onChange} />
}

export default observer(injectIntl(App))
