/*
 * Section Messages
 *
 * This contains all the text for the Section component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  headerFirst: {
    id: 'app.containers.AboutUsSection.headerFirst',
    defaultMessage: 'What is'
  },
  headerSecond: {
    id: 'app.containers.AboutUsSection.headerSecond',
    defaultMessage: 'AndonCloud?'
  },
  cardOneTitle: {
    id: 'app.containers.AboutUsSection.cardOneTitle',
    defaultMessage: 'Breakdown'
  },
  cardOneSubitle: {
    id: 'app.containers.AboutUsSection.cardOneSubitle',
    defaultMessage: 'Easy and intuitive system operated by touch screen device enables operators to easly change status of the machine. The system can indicate brakedown, materials request, or normal work.'
  },
  cardTwoTitle: {
    id: 'app.containers.AboutUsSection.cardTwoTitle',
    defaultMessage: 'Notification'
  },
  cardTwoSubtitle: {
    id: 'app.containers.AboutUsSection.cardTwoSubtitle',
    defaultMessage: 'SMS / VMS / E-mail notifications are sent immediately after machine\'s status change. Status change is also indicated on the display in production hall, using Andon colors.'
  },
  cardThreeTitle: {
    id: 'app.containers.AboutUsSection.cardThreeTitle',
    defaultMessage: 'Reaction'
  },
  cardThreeSubtitle: {
    id: 'app.containers.AboutUsSection.cardThreeSubtitle',
    defaultMessage: 'Sending notifications and indicating statuses on displays allows to minimize the delay between status change and maintenance services arrival. Status changes are also used in generated reports.'
  },
  cardFourTitle: {
    id: 'app.containers.AboutUsSection.cardFourTitle',
    defaultMessage: 'Profit'
  },
  cardFourSubtitle: {
    id: 'app.containers.AboutUsSection.cardFourSubtitle',
    defaultMessage: 'Time optimization brings a lot of profit to your company. Minimizing delay between machine\'s breakdown and maintenance services arrival brings financial profit and generating reports minimizes the amount of papers in your office.'
  },
  descriptionFirst: {
    id: 'app.containers.AboutUsSection.descriptionFirst',
    defaultMessage: 'AndonCloud'
  },
  descriptionSecond: {
    id: 'app.containers.AboutUsSection.descriptionSecond',
    defaultMessage: "is a company that helps you save money by minimizing delay between machine's breakdown and maintenance services arrival. We minimize it by providing a complete set of software, that digitilizes your company. Making digital representations of machines and their states gives the ability to instantly indicate status of a machine on a large screen in a production hall, or even in other building. Changing status can also trigger a set of notifications, eg. to people who are responsible for help."
  }
})
