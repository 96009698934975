
import AbstractCRUDStore from './AbstractCRUDStore'
import productTheme from 'cruds/productTheme'

class ProductThemeStore extends AbstractCRUDStore {
  constructor () {
    super()
    this.setCRUD(productTheme)
  }
}

export default new ProductThemeStore()
