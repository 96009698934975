import styled from 'styled-components'
import Slider from 'rc-slider/lib/Slider'
import 'rc-slider/assets/index.css'

const ValueSlider = styled(Slider).attrs({
  trackStyle: {
    backgroundColor: '#E44740'
  },
  handleStyle: {
    borderColor: '#E44740'
  }
})`
  margin-top: 16px;
  float: bottom;
`

export default ValueSlider
