/*
 * SignUpPage-Step1 Messages
 *
 * This contains all the text for the SignUpPage-Step1 component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  email: {
    id: 'app.components.Registration.email',
    defaultMessage: 'E-mail*'
  },
  name: {
    id: 'app.components.Registration.name',
    defaultMessage: 'Name*'
  },
  phoneNumber: {
    id: 'app.components.Registration.phoneNumber',
    defaultMessage: 'Phone number *'
  },
  submit: {
    id: 'app.components.Registration.submit',
    defaultMessage: 'Register'
  },
  submitLoading: {
    id: 'app.components.Registration.submitLoading',
    defaultMessage: 'Loading...'
  },
  privacy: {
    id: 'app.components.Registration.privacy',
    defaultMessage: 'We are committed to your privacy and will never share your information.'
  },
  communicatorName: {
    id: 'app.containers.Registration.communicatorName',
    defaultMessage: 'Communicator *'
  },
  communicatorIdentifier: {
    id: 'app.containers.Registration.communicatorIdentifier',
    defaultMessage: 'Communicator identifier *'
  },
  communicatorWithoutIdentifier: {
    id: 'app.containers.Registration.communicatorWithoutIdentifier',
    defaultMessage: 'We will send you a teleconference invitation by e-mail.'
  },
})
