/*
 * Banner Messages
 *
 * This contains all the text for the Banner component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  bannerTitleFirst: {
    id: 'app.components.Banner.bannerTitleFirst',
    defaultMessage: 'Maintenance services'
  },
  bannerStrongFirst: {
    id: 'app.components.Banner.bannerStrongFirst',
    defaultMessage: 'notification'
  },
  bannerTitleSecond: {
    id: 'app.components.Banner.bannerTitleSecond',
    defaultMessage: 'and downtime analysis'
  },
  bannerStrongSecond: {
    id: 'app.components.Banner.bannerStrongSecond',
    defaultMessage: 'system'
  },
  bannerSubtitleFirst: {
    id: 'app.components.Banner.bannerSubtitleFirst',
    defaultMessage: 'Create account and try AndonCloud system'
  },
  bannerSubtitleSecond: {
    id: 'app.components.Banner.bannerSubtitleSecond',
    defaultMessage: 'for 14 days completely free.'
  },
  getStarted: {
    id: 'app.components.Banner.getStarted',
    defaultMessage: 'Request a demo'
  }
})
