/*
 * Section Messages
 *
 * This contains all the text for the Section component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'app.containers.BenefitsSection.title',
    defaultMessage: 'We believe power comes from simplicity'
  },
  description: {
    id: 'app.containers.BenefitsSection.description',
    defaultMessage: 'So we’ve designed and refined every element to zero-in and double down on actions that drive to progress forward'
  },
  callToAction: {
    id: 'app.containers.BenefitsSection.callToAction',
    defaultMessage: 'description'
  },
  title0: {
    id: 'app.containers.BenefitsSection.title0',
    defaultMessage: 'Visualization of plans and production'
  },
  description0: {
    id: 'app.containers.BenefitsSection.description0',
    defaultMessage: 'The current KPI coefficients calculated in real time, affect the speed of finding places requiring optimization. Employees and managers see the results achieved on a particular position every day.'
  },
  title1: {
    id: 'app.containers.BenefitsSection.title1',
    defaultMessage: 'Digitization of the production line'
  },
  description1: {
    id: 'app.containers.BenefitsSection.description1',
    defaultMessage: 'Changing the paper documentation to a system that automatically collects production parameters improves, gives credibility and gives a real picture of the entire process. Thanks to this, monitoring the situation in the enterprise becomes much simpler and very accurate.'
  },
  title2: {
    id: 'app.containers.BenefitsSection.title2',
    defaultMessage: 'Quick reaction of maintenance'
  },
  description2: {
    id: 'app.containers.BenefitsSection.description2',
    defaultMessage: 'Instant notifications voice, SMS or e-mail effectively accelerate the reaction of maintenance services. The information collected in the system documents the work of the position, and the current production parameters available on any computer.'
  },
  title3: {
    id: 'app.containers.BenefitsSection.title3',
    defaultMessage: 'Monitoring employee effectiveness'
  },
  description3: {
    id: 'app.containers.BenefitsSection.description3',
    defaultMessage: 'Make employees aware, through numerical values, that they have an impact on achieving the goal of the entire factory. The ability to automatically generate OEE reports and presentation of current parameters on monitors allows you to monitor the situation on an ongoing basis.'
  }
})
