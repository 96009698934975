/**
 *
 * Input
 *
 */

import styled from 'styled-components'

export default styled.input`
  display:inline-block;
  box-sizing: border-box;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  background: #fff;
  background-clip: padding-box;
  color: #888888;
  font-size: 1em;
  font-weight: 200;
  height: 54px;
  width: 250px;
  padding: 0 16px 0 16px;
  outline: none;

  &:focus {
    border: 1px solid #888888;
    color: #888888;
    outline: none;
  }
  
  @media (max-width: 1300px) {
    height: 45px;
    width: 225px;
  }
  
  @media (max-width: 700px) {
    width: 50vw;
    border: 1px solid #d3d3d3;
    font-size: 1em;
    
    &:focus {
      border: 1px solid #888888;
      outline: none;
      z-index: 0;
      color: #888888;
    }
  }
`
