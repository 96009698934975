import styled from 'styled-components'

const H1 = styled.h1`
  font-size: 3.2em;
  font-weight: ${(props) => props.strong ? '900' : '300'};
  color: ${props => props.color || props.theme.text};
  line-height: 1.2em;
  text-align: center;
  text-shadow: ${props => props.shadow ? '0px 2px 10px rgba(0,0,0,0.3)' : 'none'};
  
  strong {
    font-weight: 900;
    color: ${props => props.color || props.theme.text};
  }
  
  @media (max-width: 1300px) {
    font-size: 2.5em;
  }
  
  @media (max-width: 1000px) {
    font-size: 4vw;
  }
  
  @media (max-width: 700px) {
    font-size: 1.8em;
  }
`

export default H1
