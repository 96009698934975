const routes = {
  'home': '/',
  'features': '/features',
  'platformDevices': '/platform/devices',
  'platformAnalytics': '/platform/data-analysis',
  'platformAddons': '/platform/addons',
  'platformArtificialIntelligence': '/platform/artificial-intelligence',
  'platformComputerVision': '/platform/computer-vision',
  'platformSecurity': '/platform/security',
  'platform': '/platform',
  'support': '/services/support',
  'consultancy': '/services/consultancy',
  'casestudies': '/case-studies/:id',
  'addons': '/addons/',
  'addon': '/addons/:addon_type/:id',
  'industries': '/industries',
  'resources': '/resources',
  'aboutus': '/company/aboutus',
  'democenter': '/company/democenter',
  'news': '/company/news',
  'contact': '/company/contact',
  'terms': '/terms',
  'privacy': '/privacy',

  'slides': '/slides',
  'newTrialRegistration': '/trial_registrations/new',

  'newDemoRegistration': '/demo_registrations/new'
  
  // 'guides': '/guides',
  // 'guide': '/guides/:id',

  // 'webinars': '/webinars',
  // 'webinar': '/webinars/:id'
}

export default routes
