import * as React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import routes from "utils/routes";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import AdbIcon from "@mui/icons-material/Adb";
import { Navbar, Form, Nav } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import productThemeStore from "stores/productThemeStore";

library.add(faPhone);

const Offset = styled("div")`
  @media (min-width: 0px) : {
    @media (orientation: landscape) : {
      min-height: 88px;
    }
  }
  @media (min-width: 600px) : {
    min-height: 104px;
  }
  min-height: 96px;
`;

const pages = ["Products", "Pricing", "Blog"];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const menuId = "primary-search-account-menu";

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const renderLogo = (productTheme) => {
    if (Object.keys(productTheme).length === 0) {
      return null;
    }
    console.log("renderLogo", productTheme);
    return (
      <Box mr={2}>
        <img
          src={productTheme && productTheme.logo_url}
          alt="Logo"
          width="100"
        />
      </Box>
    );
  };

  const productTheme = productThemeStore.object;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      sx={{
        width: "100%",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose} component={Link} to={routes.features}>
        <FormattedMessage {...messages.platform} />
      </MenuItem>
      <MenuItem
        onClick={handleMenuClose}
        component={Link}
        to={routes.platformDevices}
      >
        <FormattedMessage {...messages.devices} />
      </MenuItem>
      <MenuItem
        onClick={handleMenuClose}
        component={Link}
        to={routes.platformAnalytics}
      >
        <FormattedMessage {...messages.dataAndAnalytics} />
      </MenuItem>
      <MenuItem
        onClick={handleMenuClose}
        component={Link}
        to={routes.platformAddons}
      >
        <FormattedMessage {...messages.addons} />
      </MenuItem>
      <MenuItem
        onClick={handleMenuClose}
        component={Link}
        to={routes.platformComputerVision}
      >
        <FormattedMessage {...messages.computerVision} />
      </MenuItem>
      <MenuItem
        onClick={handleMenuClose}
        component={Link}
        to={routes.platformArtificialIntelligence}
      >
        <FormattedMessage {...messages.artificialIntelligence} />
      </MenuItem>
      <MenuItem
        onClick={handleMenuClose}
        component={Link}
        to={routes.platformSecurity}
      >
        <FormattedMessage {...messages.security} />
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="fixed" color="inherit" variant="outlined">
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            <a href="/">{renderLogo(productTheme)}</a>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Button
                sx={{ my: 2, display: "block" }}
                onClick={handleProfileMenuOpen}
              >
                <FormattedMessage {...messages.platform} />
              </Button>
              <Button
                sx={{ my: 2, display: "block" }}
                component={Link}
                to={routes.addons}
              >
                <FormattedMessage {...messages.extensions} />
              </Button>
              <Button
                sx={{ my: 2, display: "block" }}
                component={Link}
                to={routes.industries}
              >
                <FormattedMessage {...messages.industries} />
              </Button>
              <Button
                sx={{ my: 2, display: "block" }}
                component={Link}
                to={routes.contact}
              >
                <FormattedMessage {...messages.contact} />
              </Button>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <ButtonGroup variant="outlined">
                <Button href={routes.newTrialRegistration}>
                  <FormattedMessage {...messages.signUp} />
                </Button>
                <Button href="https://platformv2.andoncloud.com">
                  <FormattedMessage {...messages.signIn} />
                </Button>
              </ButtonGroup>
              <Button
                sx={{ ml: 2 }}
                color="primary"
                variant="text"
                ml={2}
                startIcon={<FontAwesomeIcon icon={faPhone} />}
              >
                <a href="tel:+48713407015">+48 71 340 70 15</a>
              </Button>
              <Button
                color="primary"
                variant="contained"
                href={routes.newDemoRegistration}
                className="mr-sm-2"
              >
                <FormattedMessage {...messages.scheduleADemo} />
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {renderMenu}
      <Offset />
    </>
  );
};
export default observer(ResponsiveAppBar);
