/*
 * HomePage Messages
 *
 * This contains all the text for the HomePage component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  pageDescription: {
    id: 'app.containers.HomePage.pageDescription',
    defaultMessage: 'AndonCloud is a system that helps you minimize loss in your factory or business by quickily notifying maintenance services about downtimes / shortages of materials. We also deliver OEE, MTBF, MTTR and MUR reports.'
  }
})
