import React from 'react'
import { useState, useCallback, useMemo, useEffect } from 'react';
import ReactFlow, { Background, Controls,  applyEdgeChanges, applyNodeChanges, MarkerType, Handle, Position, useReactFlow} from 'react-flow-renderer';


const defaultEdgeOptions = {
  markerStart: {
    type: MarkerType.Arrow,
    color: 'black'
  },
  style: {
    stroke: 'black'
  },
  zIndex: 2 
}

const initialEdges = [
  {
    id: 'e2-3',
    source: '2',
    target: '3',
    ...defaultEdgeOptions
  },
  {
    id: 'e2-3',
    source: '4',
    target: '5',
    ...defaultEdgeOptions
  },
];

function Flow({ url, onConnect,  children}) {

  const handleStyle = { left: 10 };

  function TextUpdaterNode({ data }) {
    return (
      <>
        <div>
          {children}
        </div>
      </>
    );
  }

  const nodeTypes = useMemo(() => ({ special: TextUpdaterNode }), [])

  const initialNodes = [
    {
      id: '1',
      type: 'special',
      position: { x: 0, y: 0 },
      style: {
        border: 'none',
        background: 'transparent',
        background: 'transparent'
      },
      draggable: false
    },
    {
      id: '2',
      type: 'input',
      position: { x: 400, y: 290 },
      style: {
        border: 'none',
        background: 'transparent'
      }
    },
    {
      id: '3',
      type: 'output',
      data: { label: 'Mozesz definiować katalogi' },
      position: { x: -50, y: 350 },
      style: {
        background: 'transparent',
        border: 'none'
      }
    },
    {
      id: '4',
      type: 'input',
      position: { x: 250, y: 150 },
      style: {
        border: 'none',
        background: 'transparent'
      }
    },
    {
      id: '5',
      type: 'output',
      data: { label: 'Standardowe kolory Andon' },
      position: { x: 530, y: 250 },
      style: {
        background: 'transparent',
        border: 'none'
      }
    },
  ];

  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const [imageIsReady, setImageIsReady] = useState(false)

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const img = new Image();
  img.onload = () => {
    // when it finishes loading, update the component state
    setImageIsReady(true);
  }
  img.src = url


  return (
    (imageIsReady ? 
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      onConnect={onConnect}
      fitView
      panOnDrag={false}
      nodesDraggable={false}
      zoomOnScroll={false}
      zoomOnPinch={false}
      connectOnClick={false}
      selectNodesOnDrag={false}
      elementsSelectable={false}
      zoomOnDoubleClick={false}
      preventScrolling={false}
      style={{maxWidth: '500px'}}
    >
      <div style={{width: '500px', height: '500px', margin: '30px 100px'}}></div>
    </ReactFlow> : null)
  );
}

export default Flow;
