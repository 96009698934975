/*
 * SignUpPage-Step1 Messages
 *
 * This contains all the text for the SignUpPage-Step1 component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'app.components.SuccessRegistration.title',
    defaultMessage: 'Done!'
  },
  subtitle: {
    id: 'app.components.SuccessRegistration.subtitle',
    defaultMessage: 'Your registration was successful!'
  },
  lead: {
    id: 'app.components.SuccessRegistration.lead',
    defaultMessage: ' - '
  },
  privacy: {
    id: 'app.containers.WebinarPage.privacy',
    defaultMessage: 'We are committed to your privacy and will never share your information.'
  }
})
