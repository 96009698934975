import React, { PureComponent } from 'react'
import Desktop from 'components/ImageViewer/Desktop'
import Mobile from 'components/ImageViewer/Mobile'
import Widget from 'components/ImageViewer/Widget'
import styled from 'styled-components'
import ImagePins from 'components/ImagePins'


const SimpleImg = styled.div`
  img {
  }
`

export default function renderImage({url, decoration}){

  let image = <SimpleImg>
      <img src={url} alt="" /> 
    </SimpleImg>
  switch (decoration) {
    case 'desktop':
      image = <Desktop url={url} />
      break;

    case 'mobile':
      image = <Mobile url={url} />
      break;

    case 'widget':
      image = <Widget url={url} />
      break;

  }

  // return <ImagePins url={url}>{image}</ImagePins>
  return image
}