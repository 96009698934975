import { action, observable } from 'mobx'
import productStore from './productStore'

class SourceStore {
  @observable.deep object = null

  @action
  recognize() {
    console.log('recognized!')
    const product = productStore.object
    const params = this.getUrlParams()
    this.object = {
      product_id: product.id,
      ...params
    }
  }

  getUrlParams() {
    var vars = {}
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value
    })
    return vars
  }

  setSiteUrl() {
    console.log(this.object, '<<< this.object');
    this.object.site_url = window.location.href
  }
}

export default new SourceStore()
