import Section from 'containers/pages/Home/Section'
import styled from 'styled-components'

const CenteredSection = styled(Section)`
  position: relative;
  text-align: center;
  padding-bottom: 5px;
`

export default CenteredSection
