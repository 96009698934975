/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import Box from '@mui/material/Box';
import Banner from 'components/Banner'
import FeaturesSection from 'containers/partials/Features'
import TryForFree from 'components/TryForFree'
import CalculatorSection from 'containers/partials/Calculator'
import AboutUsSection from 'containers/partials/AboutUs'
import BenefitsSection from 'containers/partials/Benefits'
import Footer from 'components/Footer'
import Header from 'components/Header'
import styled from 'styled-components'
import messages from './messages'
import NewDemoRegistrationContent from 'containers/pages/NewDemoRegistration/NewDemoRegistrationContent'
import Container from '@mui/material/Container';
import ReactPlayer from 'react-player'
import DesktopScreen from 'components/ImageViewer/Desktop'

const ArticleWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`
class HomePage extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    return (
      <ArticleWrapper>
        <article>
          <Helmet>
            <meta name='description' content={`${this.props.intl.formatMessage(messages.pageDescription)}`} />
          </Helmet>
          <Header path={this.props.match.path} />
          <FeaturesSection page='home' />
          <Box sx={{
            m: 2,
            display: 'flex',
            justifyContent: 'center'
          }}>
            <DesktopScreen>
              <ReactPlayer playIcon={<img src="https://cdn2.iconfinder.com/data/icons/button-v1/30/13-128.png" />} light width="1280px" height="720px" pip url='https://www.youtube.com/watch?v=56AuIyflDwg' playing />
            </DesktopScreen>
          </Box>
          <BenefitsSection />
          <CalculatorSection />
          {/* <TryForFree /> */}
          <Container maxWidth="xl" >
            <NewDemoRegistrationContent />
          </Container>
          
          {/* <AboutUsSection /> */}
          <Footer />
        </article>
      </ArticleWrapper>
    )
  }
}

export default injectIntl(HomePage)
