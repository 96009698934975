/**
 *
 * CompaniesSection
 *
 */

import React from 'react'
import styled from 'styled-components'
import messages from './messages'
import H1 from 'components/H1'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as Smile } from './assets/smile.svg'

const Wrapper = styled.div`
  padding: 20px 0;
  width: 100%;
  text-align: center;
  svg {
    margin: 20px 0;
    max-height: 64px;
  }
`

class SuccessRegistration extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    const { email } = this.props
    return (
      <Wrapper>
        <Smile />
        <H1>
          <FormattedMessage {...messages.title} />
        </H1>
        <p>
          <FormattedMessage values={{email: <b>{email}</b>}} {...messages.subtitle} />
        </p>
        <p>
          <FormattedMessage values={{email: <b>{email}</b>}} {...messages.lead} />
        </p>
      </Wrapper>
    )
  }
}

SuccessRegistration.propTypes = {
}

export default SuccessRegistration
