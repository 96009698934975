/*
 * HomePage Messages
 *
 * This contains all the text for the HomePage component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  home: {
    id: "app.components.Header.home",
    defaultMessage: "Home",
  },
  features: {
    id: "app.components.Header.features",
    defaultMessage: "Features",
  },
  platform: {
    id: "app.components.Header.platform",
    defaultMessage: "Platform",
  },
  devices: {
    id: "app.components.Header.devices",
    defaultMessage: "Devices",
  },
  dataAndAnalytics: {
    id: "app.components.Header.dataAndAnalytics",
    defaultMessage: "Data & Analytics",
  },
  addons: {
    id: "app.components.Header.addons",
    defaultMessage: "Addons",
  },
  computerVision: {
    id: "app.components.Header.computerVision",
    defaultMessage: "Computer Vision",
  },
  artificialIntelligence: {
    id: "app.components.Header.artificialIntelligence",
    defaultMessage: "Artificial Intelligence",
  },
  security: {
    id: "app.components.Header.security",
    defaultMessage: "Security",
  },
  extensions: {
    id: "app.components.Header.extensions",
    defaultMessage: "Extensions",
  },
  industries: {
    id: "app.components.Header.industries",
    defaultMessage: "Industries",
  },
  contact: {
    id: "app.components.Header.contact",
    defaultMessage: "Contact",
  },
  resources: {
    id: "app.components.Header.resources",
    defaultMessage: "Resources",
  },
  support: {
    id: "app.components.Header.support",
    defaultMessage: "Support",
  },
  signIn: {
    id: "app.components.Header.signIn",
    defaultMessage: "Sign in",
  },
  signUp: {
    id: "app.components.Header.signUp",
    defaultMessage: "Try for free",
  },
  scheduleADemo: {
    id: "app.components.Header.scheduleADemo",
    defaultMessage: "Schedule a demo",
  },
  webinars: {
    id: "app.components.Header.webinars",
    defaultMessage: "Webinars",
  },
  webinarsDesc: {
    id: "app.components.Header.webinarsDesc",
    defaultMessage:
      "Educational videos to help your team achieve more with AndonCloud.",
  },
  casestudies: {
    id: "app.components.Header.casestudies",
    defaultMessage: "Success Stories",
  },
  casestudiesDesc: {
    id: "app.components.Header.casestudiesDesc",
    defaultMessage: "Read how to use AndonCloud to grow better every day.",
  },
  demo: {
    id: "app.components.Header.demo",
    defaultMessage: "Get a demo",
  },
  helpcenter: {
    id: "app.components.Header.helpcenter",
    defaultMessage: "Help Center",
  },
  helpcenterDesc: {
    id: "app.components.Header.helpcenterDesc",
    defaultMessage:
      "Learn how to install, configure, and use AndonCloud's products.",
  },
});
