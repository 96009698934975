import AbstractCrud from './AbstractCRUD'
import Environments from '../utils/environments'

class ProductTheme extends AbstractCrud {
  constructor () {
    super()
    this.setApiUrl(Environments.API_URL)
    this.setResourcePath('product_themes')
    this.setAllowedMethods(['GET'])
  }
}

export default new ProductTheme()
