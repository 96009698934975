import styled from 'styled-components'

const SliderElement = styled.div`
  flex: 1;
  margin: 16px;
  background: #fff;
  box-shadow: 0 7px 20px rgba(0,0,0,.05);
  padding: 20px;
  border-radius: 4px;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:hover {
    box-shadow: 0 7px 55px 4px rgba(0,0,0,.035);
  }
`

export default SliderElement
