/**
 *
 * Section
 *
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import messages from './messages'
import H2 from 'components/H2'
import P from 'components/P'
import ProfitCalculator from 'containers/partials/ProfitCalculator'
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export default class CalculatorSection extends React.Component { // eslint-disable-line react/prefer-stateless-function
  render () {
    return (
      <Container maxWidth="xl" >
        <Typography variant="h2" component="h1" gutterBottom>
          <FormattedMessage {...messages.headerFirst} />
          <strong>
            <FormattedMessage {...messages.headerSecond} />
          </strong>
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <FormattedMessage {...messages.description} />
          <strong>
            <FormattedMessage {...messages.descriptionStrong} />
          </strong>
        </Typography>
        <ProfitCalculator />
      </Container>
    )
  }
}
