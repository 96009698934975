import styled from 'styled-components'

const H2 = styled.h2`
  font-size: 3.4em;
  font-weight: 300;
  line-height: 1.1;
  color: ${props => props.color || props.theme.text};
  display: inline;
  text-align: center;
  
  strong {
    font-weight: 900;
    color: ${props => props.color || props.theme.text};
  }
  
  @media (max-width: 1000px) {
    font-size: 5vw;
  }
  
  @media (max-width: 700px) {
    font-size: 7vw;
  }
  
  @media (max-width: 450px) {
    font-size: 2em;
  }
`

export default H2
