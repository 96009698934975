import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import media from 'utils/mediaQueries'
import Footer from 'components/Footer/index'
import Header from 'components/Header'
import { FormattedMessage } from 'react-intl'
import TranslatedValue, { translatedValue } from 'utils/translatedValue'
import messages from './messages'
import pathTo from 'utils/pathTo'
import slugify from 'utils/slugify'
import {Link} from 'react-router-dom'
import routes from 'utils/routes'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import staticPageStore from 'stores/staticPageStore'

function StaticPage({intl, slug}) {
  const [staticPage, setStaticPage] = React.useState({});

  useEffect(() => {
    findStaticPage()
  }, [slug]);


  const findStaticPage = async () => {
    await staticPageStore.find(slug)
    const { object } = staticPageStore
    const _staticPage = toJS(object)

    setStaticPage(_staticPage)
  }

  const renderPage = (staticPage) => {
    if (!staticPage || Object.keys(staticPage).length === 0) {
      return null
    }

    const { business_value } = staticPage
    const { image_url } = business_value

    return <>
      <Typography variant="h1" component="h1" gutterBottom>
        <TranslatedValue object={business_value} variable='title' html={true} />
      </Typography>
      <img src={image_url} width="100%" />
    </>
  }

  return (
    <>
      <article>
        <Helmet>
          <title>{intl.formatMessage(messages.pageTitle)}</title>
          <meta name='description' content={`${intl.formatMessage(messages.pageDescription)}`} />
        </Helmet>
        <Header background='#fff' />
        <Container maxWidth="xl">
          {renderPage(staticPage)}
        </Container>
        <Footer />
      </article>
    </>
  )
}

export default observer(injectIntl(StaticPage))
