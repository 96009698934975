/**
*
* LocaleToggle
*
*/

import React from 'react'
import PropTypes from 'prop-types'

import Select from './Select'
import ToggleOption from '../ToggleOption'
import styled from 'styled-components'

const Wrapper = styled.div`
  select {
    background-color: transparent;
    border-color: transparent;
  }
`

class Toggle extends React.PureComponent {
  render() {
    const { values, onToggle, messages } = this.props
    const propsValue = this.props.value

    let content = (<option>--</option>)

    if (values) {
      content = values.map((value) => (
        <ToggleOption key={value} value={value} message={messages[value]} />
      ))
    }

    return (
      <Wrapper>
        <Select value={propsValue} onChange={onToggle}>
          {content}
        </Select>
      </Wrapper>
    )
  }
}

Toggle.propTypes = {
  onToggle: PropTypes.func,
  values: PropTypes.array,
  value: PropTypes.string,
  messages: PropTypes.object
}

export default Toggle
