/**
 *
 * Select
 *
 */

import Select from 'react-select'
import styled from 'styled-components'
// import 'react-select/dist/react-select.css'

const StyledSelect = styled(Select)`
  .Select-control {
    color: #888888;
    font-size: 1em;
    font-weight: 200;
    font-family: inherit;
    height: 54px;
    width: 250px;
    max-width: 250px;
    outline: none;
    
    &:focus {
      outline: none;
      color: #888888;
    }
  }
  .Select-placeholder {
    line-height:54px;
    margin-left: 6px;
  }
  .Select-value-label {
    font-weight: 200;
    line-height:54px;
    color: #888888;
  }
  .Select-value {
    font-weight: 200;
    margin-left: 6px;
    line-height:54px;
    color: #888888;
  }
  .Select-option {
    font-weight: 200;
    color: #888888;
  }
  .Select-input {
    line-height:54px;
    margin-left: 6px;
    font-weight: 200;
    color: #888888;
    max-width: 250px;
  }
  
  @media (max-width: 700px) {
    width: 70vw;
    min-width: 0;
    
    &:focus {
      border: 1px solid #888888;
      outline: none;
      z-index: 0;
      color: #888888;
    }
  }
`

export default StyledSelect
