import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import widgetRenderLT from './images/widget-render-lt.png'
import widgetRenderLB from './images/widget-render-lb.png'
import widgetRenderRT from './images/widget-render-rt.png'
import widgetRenderRB from './images/widget-render-rb.png'

const Image = styled.img`
  border: solid 1px #989898;
  width: 100%;
`

const Border = styled.div`
  margin: -30px;
  display: flex;
  overflow: hidden;
  padding: 89px;
  background:
    url(${widgetRenderLT}) left top no-repeat,
    url(${widgetRenderLB}) left bottom no-repeat,
    url(${widgetRenderRT}) right top no-repeat,
    url(${widgetRenderRB}) right bottom no-repeat;
  `


export default function WidgetRender({url}){
  return <Border>
      <Image src={url} />
  </Border>
}