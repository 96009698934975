import React from 'react'
import { injectIntl } from 'react-intl'
import _sanitizeHtml from 'sanitize-html'


export function translatedValue(object, variable, options = {}) {
  let defaultOptions = {
    fallback: "",
    sanitizeHtml: false
  }

  const _options = {...defaultOptions, ...options}
  
  const { fallback, sanitizeHtml } = _options

  const locale = 'en'
  let value = object[`${variable}_${locale}`] || fallback

  if(sanitizeHtml){
    value = _sanitizeHtml(value, { allowedTags: [] })
  }

  if (value) {
    return value;
  }

  return `Translation missing for: ${variable}`
}

class TranslatedValue extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    const { intl, variable, object, fallback, html, children, sanitizeHtml } = this.props
    const { locale } = intl
    let value = object[`${variable}_${locale}`] || fallback

    if(sanitizeHtml){
      value = _sanitizeHtml(value, { allowedTags: [] })
    }
    
    if (children) {
      return children(value)
    }

    if (html && value) {
      return (<span dangerouslySetInnerHTML={{ __html: value }} />)
    }

    if (value) {
      return (<span>{value}</span>)
    }

    return <span />
  }
}
export default injectIntl(TranslatedValue)
