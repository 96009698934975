/*
 * TryForFree Messages
 *
 * This contains all the text for the TryForFree component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  header: {
    id: 'app.components.TryForFree.header',
    defaultMessage: 'Try AndonCloud Software for free!'
  },
  signUp: {
    id: 'app.components.TryForFree.signup',
    defaultMessage: 'Create account for free'
  }
})
