
import AbstractCRUDStore from './AbstractCRUDStore'
import systemBusinessValue from 'cruds/systemBusinessValue'

class SystemBusinessValueStore extends AbstractCRUDStore {
  constructor () {
    super()
    this.setCRUD(systemBusinessValue)
  }
}

export default new SystemBusinessValueStore()
