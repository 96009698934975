import styled from 'styled-components'

const P = styled.p`
  font-size: 18px;
  font-weight: 200;
  font-family: inherit;
  letter-spacing: .05em;
  color: #3d3d3d;
  margin-top: 10pt;
  
  @media (max-width: 1000px) {
    font-size: 16px;
  }
  
  strong {
    font-weight: 900;
  }
`

export default P
