/**
 *
 * RouterLink
 *
 */
import A from '../A'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const PlainLink = styled(A)`
  color: ${props => props.theme.accent};
  text-decoration: none;
`

const RouterLink = PlainLink.withComponent(Link)

export default RouterLink
