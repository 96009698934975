import React from "react";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import styled from "styled-components";
import A from "components/A";
import H1 from "components/H1";
import H2 from "components/H2";
import media from "utils/mediaQueries";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import DemoRegistration from "components/DemoRegistration";
import CompaniesSection from "components/CompaniesSection";
import DesktopScreen from "components/ImageViewer/Desktop";
import Header from "components/Header";
import Footer from "components/Footer";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import CheckIcon from "@material-ui/icons/Check";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import screenshot from "./screenshot.png";
import CompaniesLogos from "components/CompaniesSection/CompaniesLogos";

library.add(fas);

const Wrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 250px);
  z-index: 1;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
`;

const Claim = styled.div`
  text-align: center;
  margin-top: -15px;
  margin-bottom: 20px;
  ${media.desktop`margin-top: -15px;`};
  ${media.tablet`margin-top: 0px;`};
  font-weight: 300;
  font-size: 18px;
`;

const Contacts = styled.div`
  display: flex;
  flex-flow: row;
  @media (max-width: 1024px) {
    flex-flow: column;
  }
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 60px 0;
`;

const Contact = styled.div`
  display: flex;
  flex-flow: row;
  @media (max-width: 400px) {
    flex-flow: column;
  }
  font-size: 14px;
  align-items: center;
  justify-content: center;
  margin: 8px 6px;
  font-weight: 300;
`;

const Offices = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
`;

const Office = styled.div`
  display: flex;
  flex-flow: column;
  font-size: 18px;
  line-height: 32px;
  align-items: center;
  justify-content: center;
  margin: 8px 6px;
  font-weight: 500;
`;
const Address = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  text-align: center;
`;

const Icon = styled.div`
  font-size: 24px;
  margin: 0 10px;
  color: #e34840;
`;

const NewDemoRegistrationContent = () => {
  // eslint-disable-line react/prefer-stateless-function

  const expectations = [
    <>
      <b style={{ marginRight: "5px" }}>
        <FormattedMessage {...messages.overview} />:
      </b>
      <FormattedMessage {...messages.overviewText} />
    </>,
    <>
      <b style={{ marginRight: "5px" }}>
        <FormattedMessage {...messages.features} />:
      </b>
      <FormattedMessage {...messages.featuresText} />
    </>,
    <>
      <b style={{ marginRight: "5px" }}>
        <FormattedMessage {...messages.Q_A} />:
      </b>
      <FormattedMessage {...messages.Q_AText} />
    </>,
  ];
  return (
    <Box my={6}>
      <Typography variant="h2" component="h1">
        <FormattedMessage {...messages.title} />
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        <FormattedMessage {...messages.title} />
      </Typography>
      <Grid container spacing={3} direction="row" justifyContent="flex-end">
        <Grid item xs={7}>
          <Box mb={4}>
            <DesktopScreen url={screenshot} />
          </Box>
          <Typography variant="subtitle1" gutterBottom>
            <FormattedMessage {...messages.subtitle} />
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            <FormattedMessage {...messages.intro} />
          </Typography>
          <List component="div">
            {expectations.map((expectation) => (
              <ListItem>
                <ListItemIcon>
                  <Avatar
                    sx={{
                      backgroundColor: "palette.grey[50]",
                      color: "palette.info.main",
                    }}
                  >
                    <CheckIcon fontSize="small" color="primary" />
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary={expectation} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={5} justifyContent="flex-start">
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h3">
                <FormattedMessage {...messages.scheduleADemo} />
              </Typography>
              <DemoRegistration />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <CompaniesSection />
    </Box>
  );
};

export default NewDemoRegistrationContent;
