/**
 *
 * Banner
 *
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import Wrapper from './Wrapper'
import CenteredSection from './CenteredSection'
import H1 from '../H1'
import Subtitle from '../H2/Subtitle'
import SignUpForm from 'containers/partials/SignUpForm'
import CompaniesSection from 'components/CompaniesSection/Loadable'
import poster from '../../images/andoncloud_banner_poster.jpg'
import bannerVideo from '../../images/andoncloud_banner_video.mp4'
import styled from 'styled-components'

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
  padding-bottom: 15vh;
  
  &:after {
    content: '';
    width: 0; 
    height: 0; 
    border-top: 110px solid transparent;
    border-bottom: 110px solid #FCFCFC;
    border-left: 100vw solid transparent;
    position: absolute;
    bottom: 0;
    z-index: 2;
  }
    
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-size: cover;
    background: radial-gradient(circle, rgba(15, 14, 58, .7), rgba(26, 22, 60, .85)) center;
  }
  
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    transform: translate(-50%, -50%);
  }
`

export default class Banner extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    return (
      <Wrapper>
        <VideoWrapper>
          <video poster={poster} loop muted autoPlay>
            <source src={bannerVideo} type='video/mp4' />
            Your browser does not support the video tag. Please upgrade your browser.
          </video>
        </VideoWrapper>
        <CenteredSection>
          <H1 color='white' shadow>
            <FormattedMessage {...messages.bannerTitleFirst} />
            <strong> <FormattedMessage {...messages.bannerStrongFirst} /></strong>
            <br />
            <FormattedMessage {...messages.bannerTitleSecond} />
            <strong> <FormattedMessage {...messages.bannerStrongSecond} /></strong>
          </H1>
          <Subtitle color='white' shadow>
            <FormattedMessage {...messages.bannerSubtitleFirst} />
            <br />
            <FormattedMessage {...messages.bannerSubtitleSecond} />
          </Subtitle>
          <SignUpForm banner />
          <CompaniesSection />
        </CenteredSection>
      </Wrapper>
    )
  }
}
