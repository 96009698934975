
import AbstractCRUDStore from './AbstractCRUDStore'
import communicator from 'cruds/communicator'

class CommunicatorStore extends AbstractCRUDStore {

  constructor () {
    super()
    this.setCRUD(communicator)
  }
}

export default new CommunicatorStore()
