import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 130px);

  @media print {
    padding: 30px;
  }
  
  @media (min-width: 1000px) {
    background-size: 800px;
  }
`

export default Wrapper
