/*
 * ProfitCalculator Messages
 *
 * This contains all the text for the ProfitCalculator component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  machinesCount: {
    id: 'app.containers.ProfitCalculator.machinesCount',
    defaultMessage: 'Machines count:'
  },
  machinesDowntimeFrequency: {
    id: 'app.containers.ProfitCalculator.machinesDowntimeFrequency',
    defaultMessage: 'Downtime frequency:'
  },
  machinesDowntimeFrequencyWeek: {
    id: 'app.containers.ProfitCalculator.machinesDowntimeFrequencyWeek',
    defaultMessage: ' / mth'
  },
  helpNotificationDelay: {
    id: 'app.containers.ProfitCalculator.helpNotificationDelay',
    defaultMessage: 'Help services delay:'
  },
  productionSuspensionCost: {
    id: 'app.containers.ProfitCalculator.productionSuspensionCost',
    defaultMessage: 'Production suspension cost:'
  },
  calcResultFirst: {
    id: 'app.containers.ProfitCalculator.calcResultFirst',
    defaultMessage: 'You lose '
  },
  calcResultSecond: {
    id: 'app.containers.ProfitCalculator.calcResultSecond',
    defaultMessage: 'yearly'
  },
  currency: {
    id: 'app.containers.ProfitCalculator.currency',
    defaultMessage: 'USD'
  }
})
