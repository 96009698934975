import AbstractCrud from './AbstractCRUD'
import Environments from '../utils/environments'

class Communicator extends AbstractCrud {
  constructor () {
    super()
    this.setApiUrl(Environments.API_URL)
    this.setResourcePath('communicators')
    this.setAllowedMethods(['GET'])
  }
}

export default new Communicator()
