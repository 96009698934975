
import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'app.containers.SystemPage.pageTitle',
    defaultMessage: 'Features'
  },
  pageDescription: {
    id: 'app.containers.SystemPage.pageDescription',
    defaultMessage: 'Learn about the components of the AndonCloud system supporting the implementation of the Andon method in factories.'
  },
  header: {
    id: 'app.containers.SystemPage.header',
    defaultMessage: 'Easy system'
  },
  headerStrong: {
    id: 'app.containers.SystemPage.headerStrong',
    defaultMessage: 'for factories'
  }

})
