/*
 *
 * LanguageToggle
 *
 */

import React from 'react'

import Toggle from 'components/Toggle'
import Wrapper from './Wrapper'
import messages from './messages'
import { appLocales } from 'utils/constants'
import { observer } from 'mobx-react'
import productStore from 'stores/productStore'

@observer
class LocaleToggle extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(){
    super()
    this.onLocaleToggle = this.onLocaleToggle.bind(this)
  }

  onLocaleToggle(event){
    productStore.locale = event.target.value
  }

  render () {
    const locale = productStore.locale
    return (
      <Wrapper>
        <Toggle value={locale} values={appLocales} messages={messages} onToggle={this.onLocaleToggle} />
      </Wrapper>
    )
  }
}


export default LocaleToggle
