/**
 * A link to a certain page, an anchor tag
 */

import styled from 'styled-components'

const A = styled.a`
  color: ${props => props.theme.main};
  text-decoration: none;
  
  strong {
    font-weight: 900;
  }

  &:hover {
    color: #ce4039;
  }
`

export default A
