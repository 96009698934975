/*
 * ContactPage Messages
 *
 * This contains all the text for the  component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  pageTitle: {
    id: "app.containers.ContactPage.pageTitle",
    defaultMessage: "Contact",
  },
  pageDescription: {
    id: "app.containers.ContactPage.pageDescription",
    defaultMessage:
      "You can contact us by phone or e-mail and ask any question about our system or company. You can also visit our help center if you prefer reading instead of writing.",
  },
  title: {
    id: "app.containers.NewDemoRegistration.title",
    defaultMessage: "Request a personalized demo of AndonCloud",
  },
  subtitle: {
    id: "app.containers.NewDemoRegistration.subtitle",
    defaultMessage:
      "We look forward to showing your how our platform allows you to optimize planning, execution and measurement initiatives that matter most to your plant.",
  },
  intro: {
    id: "app.containers.NewDemoRegistration.intro",
    defaultMessage: "What can I expect?",
  },
  scheduleADemo: {
    id: "app.containers.NewDemoRegistration.scheduleADemo",
    defaultMessage: "Schedule a demo",
  },
  overview: {
    id: "app.containers.NewDemoRegistration.overview",
    defaultMessage: "Overview",
  },
  overviewText: {
    id: "app.containers.NewDemoRegistration.overviewText",
    defaultMessage:
      "A brief conversation to access what AndonCloud can do to help your strategy",
  },
  features: {
    id: "app.containers.NewDemoRegistration.features",
    defaultMessage: "Features",
  },
  featuresText: {
    id: "app.containers.NewDemoRegistration.featuresText",
    defaultMessage:
      "Live product demo with insight into how to get the most benefit from AndonCloud",
  },
  Q_A: {
    id: "app.containers.NewDemoRegistration.Q_A",
    defaultMessage: "Q&A",
  },
  Q_AText: {
    id: "app.containers.NewDemoRegistration.Q_AText",
    defaultMessage:
      "Discuss KPIs to determine the best path forward for your business",
  },
});
