
import AbstractCRUDStore from './AbstractCRUDStore'
import staticPage from 'cruds/staticPage'

class StaticPageStore extends AbstractCRUDStore {
  constructor () {
    super()
    this.setCRUD(staticPage)
  }
}

export default new StaticPageStore()
