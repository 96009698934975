/*
 * StaticPage Messages
 *
 * This contains all the text for the StaticPage component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  pageDescription: {
    id: 'app.containers.StaticPage.pageDescription',
    defaultMessage: 'Static page'
  },
  pageTitle: {
    id: 'app.containers.StaticPage.pageTitle',
    defaultMessage: 'Static page'
  },
})
