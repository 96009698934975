/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */

import { DEFAULT_LOCALE } from './utils/constants'

import plTranslationMessages from './translations/pl.json'
import enTranslationMessages from './translations/en.json'

// if (!Intl.PluralRules) {
//   require('@formatjs/intl-pluralrules/polyfill');
//   require('@formatjs/intl-pluralrules/dist/locale-data/en');
//   require('@formatjs/intl-pluralrules/dist/locale-data/pl');
// }

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages = locale !== DEFAULT_LOCALE
    ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
    : {}
  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage = !messages[key] && locale !== DEFAULT_LOCALE
      ? defaultFormattedMessages[key]
      : messages[key]
    return Object.assign(formattedMessages, { [key]: formattedMessage })
  }, {})
}

export const translationMessages = {
  pl: formatTranslationMessages('pl', plTranslationMessages),
  en: formatTranslationMessages('en', enTranslationMessages)
}
