
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MuiContainer from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TranslatedValue from 'utils/translatedValue'
import messages from './messages'
import AppWrapper from './Wrapper'
import H1 from '../H1'
import Subtitle from '../H2/Subtitle'
import Box from '@mui/material/Box';
import dashboard from 'images/dashboard.png'
import routes from 'utils/routes'
import ImageViewer from 'components/ImageViewer'


const Container = styled(MuiContainer)`
  min-height: calc(100vh - 200px);
`

const Feature = props => {
  const {
    businessValue,
    i
  } = props;
  return <Container maxWidth="xl">
        <Grid container direction={i % 2 == 0 ? "row" : "row-reverse"} spacing={4} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h2" component="h1" gutterBottom>
              <TranslatedValue object={businessValue} variable='title' html={true} />
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <TranslatedValue object={businessValue} variable='subtitle' html={true} />
            </Typography>
            <Box>
              {/* <Button color="primary" variant="contained" size="large" href={routes.newDemoRegistration}>
                <FormattedMessage {...messages.getStarted} />
              </Button> */}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <ImageViewer decoration={businessValue.image_decoration} url={businessValue.image_url} />
          </Grid>
        </Grid>
      </Container>;
};

export default Feature;