import styled from 'styled-components'

export default styled.div`
  color: #fff;
  padding: 96px 32px;
  background-color: #333;
  text-align: center;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 1px;
  color: #bbbbbb;
  line-height: 30px;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
`
