import React from 'react'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import styled from 'styled-components'
import Feature from 'components/Feature'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'

import systemBusinessValueStore from 'stores/systemBusinessValueStore'

@observer
class SystemContent extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  constructor () {
    super()
    systemBusinessValueStore.all({ page: 'home' })
  }

  renderFeatures () {
    let systemBusinessValues = systemBusinessValueStore.collection
    systemBusinessValues = toJS(systemBusinessValues) // eslint-disable-line camelcase

    return systemBusinessValues.map((systemBusinessValue, i) => {
      return this.renderFeature(systemBusinessValue, i)
    })
  }

  renderFeature(systemBusinessValue, i){
    const businessValue = systemBusinessValue.business_value
    return <>
      <Feature businessValue={businessValue} i={i} />
    </>
  }

  render () {
    return this.renderFeatures();
  }
}

export default SystemContent
