import styled from 'styled-components'

const Section = styled.section`
  margin: 3em auto 0 auto;

  &:first-child {
    margin-top: 0;
  }
`

export default Section
