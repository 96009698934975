import AbstractCrud from './AbstractCRUD'
import Environments from '../utils/environments'

class DemoRegistration extends AbstractCrud {
  constructor () {
    super()
    this.setApiUrl(Environments.PARTNERS_URL)
    this.setResourcePath('demo_registrations')
    this.setAllowedMethods(['POST', 'PATCH'])
    this.setRootKey('demo_registration')
  }
}

export default new DemoRegistration()
