
import AbstractCRUDStore from './AbstractCRUDStore'
import demoRegistration from 'cruds/demoRegistration'
import sourceStore from './sourceStore'
import calendarStore from './calendarStore'

class DemoRegistrationStore extends AbstractCRUDStore {
  constructor() {
    super()
    this.setCRUD(demoRegistration)
  }

  emptyObject() {
    return {
      client: {},
      source: {}
    }
  }

  transformRequestData(data) {
    console.log('transformRequestData - before:', data)

    data.demo_id = '1'
    data.demo_type = 'Product'
    data.planned_at = calendarStore.selectedDateTime

    sourceStore.setSiteUrl()
    const source = sourceStore.object
    data.source_attributes = source
    delete data.source

    const { client } = data
    data.client_attributes = client
    delete data.client
    console.log('transformRequestData - after:', data)
    return data
  }
}

export default new DemoRegistrationStore()
