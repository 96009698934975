import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0.5;
  height: 100vh;
`

export default Wrapper
