/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react'
import { IntlProvider } from 'react-intl'
import { observer } from 'mobx-react'
import productStore from 'stores/productStore'
import moment from 'moment'
import 'moment/locale/pl'
import 'moment-timezone'

@observer
class LanguageProvider extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    const { messages, children } = this.props

    const guessedTz = moment.tz.guess()
    moment.tz.setDefault(guessedTz)

    moment.locale(productStore.locale)
    return (
      <IntlProvider locale={productStore.locale} key={productStore.locale} messages={messages[productStore.locale]}>
        {React.Children.only(children)}
      </IntlProvider>
    )
  }
}


export default LanguageProvider
