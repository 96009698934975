/**
*
* Screen
*
*/

import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

`

const AppHead = styled.div`
  display: flex;
  flex-direction: row;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: #666;
  justify-content: center;
  padding: 20px;
`

const AppFooter = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: #666;
  justify-content: center;
  padding: 10px;
`

const AppBody = styled.div`
  background-color: #666;
`

const AppBodyImg = styled.img`
  border: solid 4px #666;
  border-radius: 10px;
  width: 100%;
`

const Speaker = styled.div`
  display: flex;
  border-radius: 10px;
  background-color: #ccc;
  width: 10%;
  height: 8px;
  margin: 4px 3px;
`

const Button = styled.div`
  display: flex;
  border-radius: 50px;
  background-color: transparent;
  border: solid 3px #ccc;
  width: 60px;
  height: 60px;
  margin: 4px 3px;
`

class Desktop extends PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    const {children, url} = this.props
    return (
      <Wrapper>
        <AppHead>
          <Speaker />
        </AppHead>
        <AppBody>
          {children ? children : <AppBodyImg src={url} alt='Screen' />}
        </AppBody>
        <AppFooter>
          <Button />
        </AppFooter>
      </Wrapper>
    )
  }
}

export default Desktop
