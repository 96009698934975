import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet'
import { Route, Switch } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HomePage from 'containers/pages/Home'
import FeaturePage from 'containers/pages/Feature/Loadable'
import AddonsPage from 'containers/pages/Addon/Index/Loadable'
import IndustriesPage from 'containers/pages/Industry/Index/Loadable'
import AddonPage from 'containers/pages/Addon/Show/Loadable'
import PlatformDevicesPage from 'containers/pages/Platform/DevicesPage/Loadable'
import ContactPage from 'containers/pages/Contact/Loadable'
import TermsPage from 'containers/pages/Terms/Loadable'
import PrivacyPage from 'containers/pages/Privacy/Loadable'
import NotFoundPage from 'containers/pages/NotFound/Loadable'
import NewTrialRegistrationPage from 'containers/pages/NewTrialRegistration/Loadable'
import NewDemoRegistrationPage from 'containers/pages/NewDemoRegistration/Loadable' 
import SystemPage from 'containers/pages/System/Loadable'
import CaseStudyPage from 'containers/pages/CaseStudy/Loadable'
import WebinarPage from 'containers/pages/Webinar/Loadable'
import WebinarsPage from 'containers/pages/Webinars/Loadable'
import StaticPage from 'containers/pages/Static/Show'
import routes from 'utils/routes'
import productStore from 'stores/productStore'
import productThemeStore from 'stores/productThemeStore'
import sourceStore from 'stores/sourceStore'


const hx = {
  fontFamily: "'Quicksand', sans-serif"
}

const theme = createTheme(
  {
    typography: {
      fontFamily: "'Open Sans', sans-serif",
      h1: hx,
      h2: hx,
      h3: hx,
      h4: hx,
      h5: hx,
      h6: hx,
    }
  }
);

console.log('theme:', {theme})

const App = props => {
  useEffect(() => {
    const findProductAndProductThemeAndSource = async () => {
      const domain = window.location.hostname;
      await productStore.find(domain);
      const product = productStore.object;
      sourceStore.recognize();
      await productThemeStore.find(product.product_theme_id);
    }
    findProductAndProductThemeAndSource()
  }, []);

  return <>
        <Helmet titleTemplate='%s - AndonCloud - OEE, MUR, Lean, MES software.' defaultTitle='AndonCloud - OEE, MUR, Lean, MES software.' />
        <ThemeProvider theme={theme}>
          <Switch>
            <Route exact path={routes.home} component={HomePage} />
            <Route exact path={routes.platformDevices} component={PlatformDevicesPage} />
            <Route exact path={routes.platformAnalytics}><StaticPage slug='platform-data-analysis' /></Route>
            <Route exact path={routes.platformAddons}><StaticPage slug='platform-addons' /></Route>
            <Route exact path={routes.platformArtificialIntelligence}><StaticPage slug='platform-artificial-intelligence' /></Route>
            <Route exact path={routes.platformComputerVision}><StaticPage slug='platform-computer-vision' /></Route>
            <Route exact path={routes.platformSecurity}><StaticPage slug='platform-security' /></Route>
            <Route exact path={routes.features} component={FeaturePage} />
            <Route exact path={routes.addons} component={AddonsPage} />
            <Route exact path={routes.addon} component={AddonPage} />
            <Route exact path={routes.industries} component={IndustriesPage} />
            <Route exact path={routes.support}><StaticPage slug='services-support' /></Route>
            <Route exact path={routes.consultancy}><StaticPage slug='services-consultancy' /></Route>
            <Route exact path={routes.democenter}><StaticPage slug='company-democenter' /></Route>
            <Route exact path={routes.news}><StaticPage slug='company-news' /></Route>
            <Route exact path={routes.aboutus}><StaticPage slug='company-aboutus' /></Route>
            <Route path={routes.contact} component={ContactPage} />
            <Route path={routes.terms} component={TermsPage} />
            <Route path={routes.privacy} component={PrivacyPage} />
            <Route path={routes.newTrialRegistration} component={NewTrialRegistrationPage} />
            <Route path={routes.newDemoRegistration} component={NewDemoRegistrationPage} />
            <Route path={routes.casestudies} component={CaseStudyPage} />
            <Route path={routes.webinar} component={WebinarPage} />
            <Route path={routes.webinars} component={WebinarsPage} />
            <Route path='' component={NotFoundPage} />
          </Switch>
        </ThemeProvider>
      </>;
};

export default App;