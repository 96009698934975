/*
 * SignUpPage-Step1 Messages
 *
 * This contains all the text for the SignUpPage-Step1 component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'app.components.DemoRegistration.title',
    defaultMessage: 'Arrange a demo meeting'
  },
  subtitle: {
    id: 'app.components.DemoRegistration.subtitle',
    defaultMessage: 'Select datetime or call a consultant to arrange a meeting'
  },
  date: {
    id: 'app.components.DemoRegistration.date',
    defaultMessage: 'Selected date and time of teleconference:'
  }
})
