import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  margin: 6px 0;
  display: inline-block;
`

const FieldArea = styled.div`
  display: flex;
  flex-direction: row;
`

// const Error = styled.div`
//     color: #666;
//     font-size: 70%;
//     text-align: right;
// `

class Field extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    const { children } = this.props
    return (
      <Wrapper>
        <FieldArea>
          {children}
        </FieldArea>
        {/* <Error>Tekst</Error> */}
      </Wrapper>
    )
  }
}

Field.propTypes = {
}

export default Field
