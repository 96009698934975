import Development from './development'
import Production from './production'

let config
if (process.env.REACT_APP_API_ENV === 'production') {
  config = Production
} else {
  config = Development
}

export default config
