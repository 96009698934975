import styled from 'styled-components'

const Subtitle = styled.h2`
  font-size: 1.8em;
  font-weight: 300;
  color: ${props => props.color || props.theme.text};
  line-height: 1.2em;
  text-align: center;
  text-shadow: ${props => props.shadow ? '0px 2px 10px rgba(0,0,0,0.3)' : 'none'};
  
  @media (max-width: 1300px) {
    font-size: 1.4em;
  }
  
  @media (max-width: 1000px) {
    font-size: 2.2vw;
  }
  
  @media (max-width: 700px) {
    font-size: 1.2em;
  }
  
   @media (max-width: 450px) {
    font-size: 1em;
  }
`

export default Subtitle
