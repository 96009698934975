import React from 'react'
import styled from 'styled-components'
import Wrapper from './Wrapper'
import { ReactComponent as Cloud } from './CloudLoader.svg'

const CloudLoader = styled(Cloud)`
  height: 100px;
`

const LoadingIndicator = () => (
  <Wrapper>
    <CloudLoader />
  </Wrapper>
)

export default LoadingIndicator
