import AbstractCrud from './AbstractCRUD'
import Environments from '../utils/environments'

class TrialRegistration extends AbstractCrud {
  constructor () {
    super()
    this.setApiUrl(Environments.PARTNERS_URL)
    this.setResourcePath('trial_registrations')
    this.setAllowedMethods(['POST', 'PATCH'])
    this.setRootKey('trial_registration')
  }
}

export default new TrialRegistration()
