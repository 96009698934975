import {Link} from 'react-router-dom'
import styled from 'styled-components'

export default styled(Link)`
  display: inline-flex;
  padding: 0em 2em;
  background: ${props => props.outlined ? 'transparent' : '#e34840'};
  color: ${props => props.outlined ? '#3d3d3d' : '#fff'};
  height: 100%;
  margin-top: ${props => props.mobile ? '30px' : '0px'};
  margin-bottom: ${(props) => props.mobile ? '16px' : '0px'};
  line-height: ${(props) => props.mobile ? '3' : '40px'};
  text-decoration: none;
  border-radius: 4px;
  border: ${props => props.outlined ? '2px solid #bdbdbd' : 'none'};
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  font-weight: ${props => props.mobile ? '200' : '400'};
  font-size: ${(props) => props.mobile ? '22px' : '12px'};
  letter-spacing: 0.1em;
  text-transform: uppercase;
  
  &:nth-child(1) {
    margin-top: 0px;
  }
  
  &:hover {
    background: ${props => props.outlined ? 'transparent' : '#e34840'};
  }

  &:active {
    ${props => props.outlined ? '#3d3d3d' : '#fff'};
    box-shadow: inset 0px 2px 5px rgba(0,0,0,0.15);
  }
`
