/**
 *
 * InputButton
 *
 */

import styled from 'styled-components'

export default styled.input.attrs((props) => ({
  type: props.type || 'button'
}))`
  height: 54px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0em 2em;
  background: #e34840;
  color: #fff;
  line-height: ${(props) => props.mobile ? '3' : '40px'};
  text-decoration: none;
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  
  &:nth-child(1) {
    margin-top: 0;
  }
  
  &:hover {
    background: #e34840;
  }

  &:active {
    color: #fff;
    box-shadow: inset 0 2px 5px rgba(0,0,0,0.15);
  }
  
  @media (max-width: 1300px) {
    height: 45px;
  }
  
  @media (max-width: 700px) {
    width: 50vw;
    margin-left: 0;
    margin-top: 5px;
    
    &:focus {
      border: 1px solid #3d3d3d;
      border-bottom: none;
      outline: none;
      z-index: 0;
    }
  }
`
