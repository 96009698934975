/**
 *
 * Registration
 *
 */

 import React from 'react'
 import { toJS } from 'mobx'
 import PropTypes from 'prop-types'
 import Input from 'components/Input'
 import P from 'components/P'
 import { FormattedMessage } from 'react-intl'
 import media from 'utils/mediaQueries'
 import styled from 'styled-components'
 import messages from './messages'
 import { observer } from 'mobx-react'
 import SuccessRegistration from 'components/SuccessRegistration'
 import communicatorStore from 'stores/communicatorStore'
 import { library } from '@fortawesome/fontawesome-svg-core'
 import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
 // import MuiPhoneNumber from 'material-ui-phone-number';
 import TextField from '@mui/material/TextField';
 import InputAdornment from '@mui/material/InputAdornment';
 import Field from 'components/Field'
 import FormControl from '@mui/material/FormControl';
 import { makeStyles } from '@mui/material/styles';
 import InputButton from '../InputButton'
 import StyledSelect from '../Select'
 import find from 'lodash/find'
 import SimpleCalendar from 'components/SimpleCalendar'
 import Button from '@mui/material/Button';
 
 library.add(faCheckSquare)
 
 const Wrapper = styled.div`
   width: 100%;
   display: flex;
   flex-direction: row;
   ${media.tablet`flex-direction: column;`};
 `
 
 const SimpleCard = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   flex: 2;
 
   box-shadow: 0 10px 30px rgba(0,0,0,.07);
   padding: 30px;
   transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
   @media print {
     page-break-after: always;
   }
 `
 
 const Description = styled(SimpleCard)`
   flex: 3;
   padding: 30px;
   text-align: left;
   border-top-right-radius: 0;
   border-top-left-radius: 4px;
 `
 
 const Info = styled(P)`
   color: #a5a3b5;
   font-weight: 200;
   font-size: 14px;
   text-align: center;
   min-height: 40px;
 `
 
 const StyledInputButton = styled(InputButton)`
   margin-top: 20px;
 `
 
 const CommunicatorWithoutIdentifierInfo = styled.div`
   display: flex;
   flex: 4;
   padding: 15px 20px;
 `
 
 const StyledInput = styled(Input)`
   display: flex;
   flex: 4;
 `
 const StyledSelectWithMargin = styled(StyledSelect)`
   height: 54px;
   display: flex;
   flex: 2;
   > div {
     width: 100%;
     max-width: none;
   }
 `
 
 const InputsForm = styled.form`
 `
 
 
 const fieldMargin = 'normal'
 
 @observer
 class Registration extends React.Component { // eslint-disable-line react/prefer-stateless-function
   constructor (props) {
     super(props)
     const fields = this.props.fields || ['client.name', 'client.email']
     this.state = {
       fields,
       communicatorNameSelectedOption: null
     }
     this.handleRegistration = this.handleRegistration.bind(this)
     this.handleNameChange = this.handleNameChange.bind(this)
     this.handleEmailChange = this.handleEmailChange.bind(this)
     this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this)
     this.handleCommunicatorNameChange = this.handleCommunicatorNameChange.bind(this)
     this.handleCommunicatorIdentifierChange = this.handleCommunicatorIdentifierChange.bind(this)
     this.handlePlannedAtChange = this.handlePlannedAtChange.bind(this)
     this.fieldsTypes = {
       'client.name': this.renderClientNameInput.bind(this),
       'client.email': this.renderClientEmailInput.bind(this),
       'client.phone_number': this.renderClientPhoneNumberInput.bind(this),
       'client.communicator': this.renderClientCommunicatorInput.bind(this),
       'client.planned_at': this.renderClientPlannedAtInput.bind(this)
     }
 
     communicatorStore.all()
   }
 
   renderClientNameInput () {
     return (
       <FormattedMessage {...messages.name}>
         {
           (placeholder) => <TextField
             required
             label={placeholder}
             variant="outlined"
             margin={fieldMargin}
             value={this.props.registrationStore.object.client.name}
             onChange={this.handleNameChange}
         
           /> 
         }
       </FormattedMessage>
     )
   }
 
   renderClientEmailInput () {
     return (
       <FormattedMessage {...messages.email}>
         {
           (placeholder) => <TextField
           required
           label={placeholder}
           variant="outlined"
           margin={fieldMargin}
           value={this.props.registrationStore.object.client.email}
           onChange={this.handleEmailChange}
         /> 
         }
       </FormattedMessage>
     )
   }
 
   renderClientPhoneNumberInput () {
     return (
       <FormattedMessage {...messages.phoneNumber}>
       {
           (placeholder) => 
           // <MuiPhoneNumber defaultCountry={'us'} onChange={this.handlePhoneNumberChange}/>
           <TextField
             required
             label={placeholder}
             margin={fieldMargin}
             variant="outlined"
             value={this.props.registrationStore.object.client.phone_number}
             onChange={this.handlePhoneNumberChange}
           /> 
         }
       </FormattedMessage>
     )
   }
 
   renderClientCommunicatorInput () {
     const { communicatorNameSelectedOption } = this.state
     const communicatorNameSelectedOptionValue = communicatorNameSelectedOption && communicatorNameSelectedOption.value
     console.log('communicatorStore.collection:', communicatorStore.collection, 'store:', communicatorStore)
     const selectedCommunicator = find(communicatorStore.collection, {name_en: communicatorNameSelectedOptionValue})
 
     return (
       <Field>
         {console.log('xxx')}
         {console.log('communicatorStore:', toJS(communicatorStore))}
         {(communicatorStore.collection) ? (
           <FormattedMessage {...messages.communicatorName}>
             {
                 (placeholder) => <StyledSelectWithMargin
                   placeholder={placeholder}
                   clearable={false}
                   value={this.state.communicatorNameSelectedOption}
                   onChange={this.handleCommunicatorNameChange}
                   options={communicatorStore.collection.map((c) => {
                     return {
                       value: c.name_en,
                       label: <div>
                         <img src={c.icon_url} height='20px' width='20px' alt={c.name_en} style={{margin: '2px 7px 2px 0'}} />
                         {c.name_en} 
                       </div>}
                   }
                   )}
                   autocomplete='off' />
               }
           </FormattedMessage>
         ) : null}
           {this.renderClientCommunicatorIdentifierInput(selectedCommunicator)}
       </Field>
     )
   }
 
   renderClientCommunicatorIdentifierInput(selectedCommunicator){
     if(!selectedCommunicator){
       return null;
     }
     const communicatorIdentifierPlaceholder = selectedCommunicator && selectedCommunicator.identifier_name_en
     if(selectedCommunicator.identifier_required){
       return (
         <FormattedMessage {...messages.communicatorIdentifier}>
           {
             (placeholder) => <StyledInput type='text' required placeholder={communicatorIdentifierPlaceholder || placeholder} defaultValue={this.props.registrationStore.object.client.communicator_identifier} onChange={this.handleCommunicatorIdentifierChange} />
           }
         </FormattedMessage>)
     } else {
       return (
         <CommunicatorWithoutIdentifierInfo>
            <FormattedMessage {...messages.communicatorWithoutIdentifier} />
         </CommunicatorWithoutIdentifierInfo>
       )
     }
   }
 
   renderClientPlannedAtInput(){
     return <SimpleCalendar userId={1} onChange={this.handlePlannedAtChange} />
   }
 
   handleEmailChange (event) {
     this.props.registrationStore.setObject({client: {email: event.target.value}})
   }
 
   handleNameChange (event) {
     this.props.registrationStore.setObject({client: {name: event.target.value}})
   }
 
   handlePhoneNumberChange (event) {
     this.props.registrationStore.setObject({client: {phone_number: event.target.value}})
   }
 
   handleCommunicatorNameChange (option) {
     this.setState({communicatorNameSelectedOption: option})
     const { value } = option
     this.props.registrationStore.setObject({client: {communicator_name: value}})
   }
 
   handleCommunicatorIdentifierChange (event) {
     this.props.registrationStore.setObject({client: {communicator_identifier: event.target.value}})
   }
 
   handlePlannedAtChange (value) {
     this.props.registrationStore.setObject({client: {planned_at: value}})
   }
 
   async handleRegistration (event) {
    console.log('test')
     event.preventDefault()
     await this.props.registrationStore.updateOrCreate()
   }
 
  getInputButton () {
    return (
      <Button color="primary" loading={this.props.registrationStore.objectLoading} variant="contained" size="large" onClick={this.handleRegistration}>
        <FormattedMessage {...messages.submit} />
      </Button>
    )
  }
 
   renderInputsForm () {
     console.log('renderSuccessForm')
     const { registrationHeader } = this.props
     return (
       <FormControl fullWidth>
         {registrationHeader}
         { this.renderInputs() }
         { this.getInputButton() }
         <Info>
           <FormattedMessage {...messages.privacy} />
         </Info>
       </FormControl>
     )
   }
 
   renderInputs () {
     return this.state.fields.map((field) => {
       const fieldFunction = this.fieldsTypes[field]
       if (!fieldFunction) {
         throw new Error(`Field ${field} is not defined in Registration component`)
       }
       const fieldRender = fieldFunction()
       return fieldRender
     })
   }
 
   renderSuccessForm () {
     console.log('renderSuccessForm')
     return (
       <SuccessRegistration email={this.props.registrationStore.object.client.email} />
     )
   }
 
   renderForm () {
     console.log('this.props.registrationStore:', this.props.registrationStore.isPersisted)
     const { registrationStore } = this.props
     if (!registrationStore) {
       return null
     }
     if (registrationStore.isPersisted) {
       return this.renderSuccessForm()
     } else {
       return this.renderInputsForm()
     }
   }
 
   render () {
     console.log('renderererasdasdasd')
 
     return (
       <Wrapper className='registration-wrapper'>
         {this.renderForm()}
       </Wrapper>
     )
   }
 }
 
 Registration.contextTypes = {store: PropTypes.object}
 export default Registration
 