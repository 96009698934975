/**
 *
 * SignUpForm
 *
 */

import React from 'react'
import { withRouter } from 'react-router-dom';
import Input from 'components/Input'
import InputButton from 'components/InputButton'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import Wrapper from './Wrapper'
import { observer } from 'mobx-react'
import P from 'components/P'
import RouterLink from 'components/RouterLink'
import routes from 'utils/routes'
import styled from 'styled-components'
import trialRegistrationStore from 'stores/trialRegistrationStore'

const FormsWrapper = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  
  Input {
    &:nth-child(1) {
      margin-right: 10px;
    }
    @media (max-width: 700px) {
      &:nth-child(1) {
        margin-right: 0;
      }
    }
  }
    
  @media (max-width: 700px) {
    flex-direction: column;
  }
`
const Info = styled(P)`
  color: #a5a3b5;
  font-weight: 200;
  font-size: 14px;
  text-align: center;
  min-height: 40px;
  margin-top: 10px;
`

@observer
class SignUpForm extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props)
    this.state = {
      showInfo: this.props.infoShown || false
    }
    this.handleRegistration = this.handleRegistration.bind(this)
  }

  async handleRegistration (event) {
    event.preventDefault()
    await trialRegistrationStore.updateOrCreate()
    if (trialRegistrationStore.isPersisted) {
      this.props.history.push(routes.newTrialRegistration)
    }
  }

  handleEmailChange (event) {
    trialRegistrationStore.setObject({ client: { email: event.target.value } })
  }

  getInputButton () {
    if (trialRegistrationStore.objectLoading) {
      return (
        <FormattedMessage {...messages.buttonLoading}>
          {
            (value) => <InputButton type='submit' value={value} />
          }
        </FormattedMessage>
      )
    } else {
      return (
        <FormattedMessage {...messages.button}>
          {
            (value) => <InputButton type='submit' value={value} />
          }
        </FormattedMessage>
      )
    }
  }

  render () {
    return (
      <Wrapper>
        <FormsWrapper onSubmit={this.handleRegistration}>
          <FormattedMessage {...messages.input}>
            {
              (placeholder) => <Input type='email' onFocus={() => { this.setState({showInfo: true}) }} required placeholder={placeholder} defaultValue={trialRegistrationStore.object.email} onChange={this.handleEmailChange} />
            }
          </FormattedMessage>
          { this.getInputButton() }
        </FormsWrapper>
        {
          this.state.showInfo ? <Info>
            <FormattedMessage {...messages.info} />
            <RouterLink to={routes.terms}><FormattedMessage {...messages.terms} /></RouterLink>
            <FormattedMessage {...messages.and} />
            <RouterLink to={routes.privacy}><FormattedMessage {...messages.privacy} /></RouterLink>
          </Info>
            : <Info />
        }
      </Wrapper>
    )
  }
}

export default withRouter(SignUpForm)
