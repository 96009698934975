/*
 * SignUpForm Messages
 *
 * This contains all the text for the SignUpForm component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  input: {
    id: 'app.containers.SignUpForm.input',
    defaultMessage: 'yourbussiness@email.com'
  },
  button: {
    id: 'app.containers.SignUpForm.button',
    defaultMessage: 'Try AndonCloud'
  },
  buttonLoading: {
    id: 'app.containers.SignUpForm.buttonLoading',
    defaultMessage: 'Loading...'
  },
  info: {
    id: 'app.containers.SignUpForm.info',
    defaultMessage: 'By creating an account you agree to '
  },
  terms: {
    id: 'app.containers.SignUpForm.terms',
    defaultMessage: 'Terms & Conditions '
  },
  and: {
    id: 'app.containers.SignUpForm.and',
    defaultMessage: 'and '
  },
  privacy: {
    id: 'app.containers.SignUpForm.privacy',
    defaultMessage: 'Privacy Policy.'
  }
})
