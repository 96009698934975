/**
*
* Screen
*
*/

import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

`

const AppHead = styled.div`
  display: flex;
  flex-direction: row;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #ccc;
  padding: 4px;
`

const AppBody = styled.div`
`

const AppBodyImg = styled.img`
  border: solid 1px #ccc;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
`

const Dot = styled.div`
  display: flex;
  border-radius: 10px;
  background-color: white;
  width: 8px;
  height: 8px;
  margin: 4px 3px;
`

class Mobile extends PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    const {children, url} = this.props
    return (
      <Wrapper>
        <AppHead>
          <Dot />
          <Dot />
          <Dot />
        </AppHead>
        <AppBody>
          {children ? children : <AppBodyImg src={url} alt='Screen' />}
        </AppBody>
      </Wrapper>
    )
  }
}
export default Mobile
