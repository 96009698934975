export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount'
export const DAEMON = '@@saga-injector/daemon'
export const PLATFORM_URL = 'https://platform.andoncloud.com/'
export const GRID_URL = 'https://grid.andoncloud.com/'
export const ADMIN_URL = 'https://admin.andoncloud.com/'
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount'
export const appLocales = ['pl', 'en']

let DEFAULT_LOCALE = 'en'
if (typeof window !== 'undefined' && window.location) {
  const hostName = window.location.hostname
  const tldDomain = hostName.substring(hostName.lastIndexOf('.') + 1)
  DEFAULT_LOCALE = appLocales.includes(tldDomain) ? tldDomain : 'en'
}
export { DEFAULT_LOCALE }
