/**
 *
 * CompaniesSection
 *
 */

import React from 'react'
import styled from 'styled-components'
import CompaniesLogos from './CompaniesLogos'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  page-break-after: always;
  padding-top: 50px;
  filter: opacity(.85);
  z-index: 0;
  @media (max-width: 1300px) {
    padding-top: 0;
  }
`

class CompaniesSection extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render () {
    return (
      <>
        <Wrapper>
          <CompaniesLogos />
        </Wrapper>
      </>
    )
  }
}

CompaniesSection.propTypes = {}

export default CompaniesSection
