import React from 'react';
import { FormattedMessage } from 'react-intl'
import { toJS } from 'mobx'
import messages from './messages'
import styled from 'styled-components'
import { makeStyles } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Registration from 'components/Registration'
import demoRegistrationStore from 'stores/demoRegistrationStore'
import Grid from '@mui/material/Grid';
import productStore from 'stores/productStore'
import { observer } from 'mobx-react'


function DemoRegistration() {

  let product = productStore.object
  product = toJS(product)

  if (Object.keys(product).length === 0) {
    return null
  }

  console.log({product})

  // const { agents } = product
  // const agent = agents[Math.floor(Math.random() * agents.length)]

  return (
    <Registration registrationStore={demoRegistrationStore} fields={['client.name', 'client.phone_number', 'client.email', 'client.planned_at']} />
  );
}

export default observer(DemoRegistration)