/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  authorMessage: {
    id: "app.components.Footer.author.message",
    defaultMessage: `© 2016-{currentYear} AndonCloud. All Rights Reserved`,
    values: { currentYear: new Date().getFullYear() },
  },
  home: {
    id: "app.components.Footer.home",
    defaultMessage: "Home",
  },
  features: {
    id: "app.components.Footer.features",
    defaultMessage: "Features",
  },
  system: {
    id: "app.components.Footer.system",
    defaultMessage: "System",
  },
  casestudies: {
    id: "app.components.Footer.casestudies",
    defaultMessage: "Success Stories",
  },
  webinars: {
    id: "app.components.Footer.webinars",
    defaultMessage: "Webinars",
  },
  contact: {
    id: "app.components.Footer.contact",
    defaultMessage: "Contact",
  },
  consultancy: {
    id: "app.components.Footer.consultancy",
    defaultMessage: "Consultancy",
  },
  support: {
    id: "app.components.Footer.support",
    defaultMessage: "Support",
  },
  terms: {
    id: "app.components.Footer.terms",
    defaultMessage: "Terms and conditions",
  },
  privacy: {
    id: "app.components.Footer.privacy",
    defaultMessage: "Privacy",
  },
  guide: {
    id: "app.components.Footer.guide",
    defaultMessage: "Get guide",
  },
  figureOut: {
    id: "app.components.Footer.figureOut",
    defaultMessage: "Figure out",
  },
  platform: {
    id: "app.components.Footer.platform",
    defaultMessage: "Platform",
  },
  addons: {
    id: "app.components.Footer.addons",
    defaultMessage: "Addons",
  },
  industries: {
    id: "app.components.Footer.industries",
    defaultMessage: "Industries",
  },
  informationFor: {
    id: "app.components.Footer.informationFor",
    defaultMessage: "Information for",
  },
  developers: {
    id: "app.components.Footer.developers",
    defaultMessage: "Developers",
  },
  businessPartners: {
    id: "app.components.Footer.businessPartners",
    defaultMessage: "Business partners",
  },
  stayConnected: {
    id: "app.components.Footer.stayConnected",
    defaultMessage: "Stay connected with AndonCloud",
  },
  about: {
    id: "app.components.Footer.about",
    defaultMessage: "About AndonCloud",
  },
  news: {
    id: "app.components.Footer.news",
    defaultMessage: "News",
  },
  demoCenter: {
    id: "app.components.Footer.demoCenter",
    defaultMessage: "Demo center",
  },
});
