import styled from 'styled-components'

const H3 = styled.h3`
  font-size: 2.4em;
  font-weight: 300;
  line-height: 1.1;
  color: ${props => props.color || props.theme.text};
  display: inline;
  strong {
    font-weight: 900;
    color: ${props => props.color || props.theme.text};
  }

  @media (max-width: 700px) {
    font-size: 6vw;
  }
  
  @media (max-width: 450px) {
    font-size: 1.6em;
  }
  strong {
    font-weight: 900;
    text-shadow: 0 3px 6px rgba(0,0,0,.2);
  }
`

export default H3
