
import AbstractCRUDStore from './AbstractCRUDStore'
import product from 'cruds/product'
import { observable } from 'mobx'

class ProductStore extends AbstractCRUDStore {
  @observable locale = undefined

  constructor () {
    super()
    this.setCRUD(product)
  }

  transformResponseData(data){
    if(!this.locale){
      this.locale = data.default_locale
    }
    return data
  }
}

export default new ProductStore()
