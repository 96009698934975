/**
*
* ToggleOption
*
*/

import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

const ToggleOption = ({ value, message, intl }) => (
  <option value={value}>
    {message ? intl.formatMessage(message) : value}
  </option>
)

ToggleOption.propTypes = {
  value: PropTypes.string.isRequired,
  message: PropTypes.object,
}

export default injectIntl(ToggleOption)
