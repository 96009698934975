import { action, observable } from 'mobx'
import AbstractCRUDStore from './AbstractCRUDStore'
import calendar from 'cruds/calendar'

class CalendarStore extends AbstractCRUDStore {
  @observable selectedDateTime = false

  constructor () {
    super()
    this.setCRUD(calendar)
  }

  @action
  setSelectedDateTime (datetime) {
    this.selectedDateTime = datetime
  }
}

export default new CalendarStore()
