import React from 'react'

import { FormattedMessage } from 'react-intl'
import messages from './messages'
import Wrapper from './Wrapper'
import CallToAction from 'components/CallToAction'
import routes from 'utils/routes'
import styled from 'styled-components'

const CTAWrapper = styled.div`
  margin: 25px;
  @media print {
    display: none;
  }
`

class TryForFree extends React.Component { // eslint-disable-line react/prefer-stateless-function
  render () {
    return (
      <Wrapper>
        <section>
          <FormattedMessage {...messages.header} />
        </section>
        <section>
          <CTAWrapper>
            <CallToAction to={routes.newTrialRegistration}>
              <FormattedMessage {...messages.signUp} />
            </CallToAction>
          </CTAWrapper>
        </section>
      </Wrapper>
    )
  }
}

export default TryForFree
