import brokenMachineIcon from './assets/broken_machine_icon.png'
import helpServicesIcon from './assets/help_services_icon.png'
import profitIcon from './assets/profit_icon.png'
import sendIcon from './assets/send_icon.png'

import styled from 'styled-components'

const Card = styled.div`
  display: table-cell;
  width: 25%;
  position: relative;
  padding: 40px 40px 20px 40px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.07);
  border-radius: 4px;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  
  P {
    color: #828282;
    @media (max-width: 1400px) {
      font-size: .95em;
    }
    @media (max-width: 1300px) {
      font-size: .9em;
    }
    @media (max-width: 1200px) {
      font-size: inherit;
    }
  }
  
  &:before {
    content: '';
    border-radius: 50%;
    height: 70px;
    width: 70px;
    top: -35px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    position: absolute;
    bottom: 100%;
  }
  
  @media (max-width: 1200px) {
    width: 100%;
    padding: 40px 40px 40px 40px;
  }
  
  &:nth-child(1) {
    z-index: 4;
    @media (max-width: 1200px) {
      z-index: 1;
    }
    &:before {
      background: #fff;
      background: url(${brokenMachineIcon});
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  &:nth-child(2) {
    z-index: 3;
    @media (max-width: 1200px) {
      z-index: 2;
    }
    &:before {
      background: url(${sendIcon});
      background-size: contain;
    }
  }
  &:nth-child(3) {
    z-index: 2;
    @media (max-width: 1200px) {
      z-index: 3;
    }
    &:before {
      background: url(${helpServicesIcon});
      background-size: contain;
    }
  }
  &:nth-child(4) {
    z-index: 1;
    @media (max-width: 1200px) {
      z-index: 4;
    }
    &:before {
      background: url(${profitIcon});
      background-size: contain;
    }
    &:after {
      display: none;
    }
  }
  
  &:after {
    border-bottom: 20px solid transparent;
    border-top: 20px solid transparent;
    border-left: 20px solid #fff;
    position: absolute;
    content: "";
    top: 100px;
    left: 100%;
    width: 0; 
    height: 0;
    
    @media (max-width: 1200px) {
      display: none;
    }
  }
  &:hover {
    box-shadow: 10px 5px 40px rgba(0,0,0,.1);
  }
`

export default Card
